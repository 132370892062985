import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useContext,
} from "react";
import {
  Container,
  Row,
  Input,
  Table,
  CardHeader,
  CardFooter,
  Col,
  Card,
  InputGroup,
  CardBody,
  Media,
  Label,
  ModalBody,
  ModalHeader,
  Modal,
  Form,
} from "reactstrap";
import cloneDeep from "lodash/cloneDeep";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import "../../../assets/scss/index.less";
import { Btn, H4, H1, Badges, H6 } from "../../../AbstractElements";
import * as queries from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";
import { toast } from "react-toastify";
import { useNavigate, useLocation, Link } from "react-router-dom";
import moment from "moment";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import { SiteContext } from "../../../App";
import { Grid } from "react-feather";
import Dropdown from "react-bootstrap/Dropdown";
import Select from "react-select";
import { limit } from "../../../Config/Config";
import useInfiniteLoading from "./infineteloop";
import SiteDBData from "../../../Data/DistributionBoard/index";
import SiteData from "../../../Data/Sites/index";
import CategoryData from "../../../Data/Category/index";
import LoginForm from "../../Pages/Auth/LoginForm";
import InfiniteScroll from "react-infinite-scroll-component";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});
var base64 = require("base-64");

const AssetsListDetails = () => {
  const navigate = useNavigate();
  const state = useLocation();
  const [fullAssets, setFullAssets] = useState([]);
  const [value, setValue] = useState("");
  const [selectedSite, setSelectedSite] = useState("");
  const [selectedSearchSiteData, setSelectedSearchSiteData] = useState("");
  const [selectedSearchDBData, setSelectedSearchDBData] = useState("");
  const [sites, setSites] = useState();
  const [siteDetails, setSiteDetails] = useState([]);
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const userPermission =
    LoginUserDetails.hasOwnProperty("permissions") &&
      LoginUserDetails.permissions !== null
      ? JSON.parse(LoginUserDetails.permissions)
      : [];
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [distributionBoxList, setDistributionBoxList] = useState([]);
  const [distributionBoxFilter, setDistributionBoxfilter] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [selectedBox, setSelectedBox] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [assetView, setAssetView] = useState("list");
  const LoginUserGroup = localStorage.getItem("groups");
  const countPerPageCard = 8;
  const [currentPageCard, setCurrentPageCard] = useState(1);
  const [assetDemo, setAssetsDemo] = React.useState([]);
  const [assetDemoCard, setAssetsDemoCard] = React.useState([]);
  const [collectionCard, setCollectionCard] = React.useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const countPerPage = 10;
  const [filteredData, setFilteredData] = React.useState([]);
  const [assetTemplatData, setAssetTemplatData] = React.useState([]);
  const [activedata, setactiveData] = React.useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [deleteAsset, setDelete] = useState(false);
  const [deleteAllAsset, setDeleteAllAssets] = useState(false);
  const [selectedItemsAsset, setSelectedItemsAsset] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const { selectedSiteGlobal, handleSiteChange } = useContext(SiteContext);
  const [assetsCount, setAssetsCount] = useState([]);
  const [subscriptionPlan, setsubscriptionplan] = useState([]);
  const [addOn, setAddOn] = useState([]);
  const [assetPlancount, setAssetplancount] = useState([]);
  const [searchAssets, setSearchAssets] = useState(null);
  const [searchItems, setSearchItems] = useState([]);
  const [fullItems, setFullItems] = useState([]);
  //const [limit, setLimit] = useState(100000);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteAllModal, setDeleteAllModal] = useState(false);
  const [isSecoundSearch, setIsSecoundSearch] = useState(false);
  const [oldSiteId, setOldSiteId] = useState(false);
  const [assetscountbysite, setAssetscountbysite] = useState([]);
  const [searchText, setSearchtext] = useState(false);
  //let assetRecords = [];
  const assetRecordsRef = useRef([]);
  const assetRecordsSearchRef = useRef([]);
  const assetRecordsSearchDBRef = useRef([]);
  const deleteToggle = () => {
    console.log(selectedItemsAsset);
    selectedItemsAsset.length > 0
      ? setDeleteModal(!deleteModal)
      : setDeleteModal(false);
  };
  const deleteAllToggle = () => {
    setDeleteAllModal(!deleteAllModal);
  };
  const [assetsCountBySite, setAssetsCountBySite] = useState(
    CategoryData.getCategoryAssetCounts()
  );
  console.log(assetsCountBySite);
  const catid =
    state.state && state.state.category ? state.state.category.id : "";
  const [items, setItems] = useState([]);
  const [siteIndex, setSiteIndex] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [nextToken, setNextToken] = useState(null);
  var isReset = false;
  var isDBChange = false;
  useEffect(() => {
    try {
      console.log(localStorage.getItem("categoryAssetCount"));
      if (LoginUserGroup === "site_user") {
        fetchSubscriptionListSUAssetsCount();
      } else {
        if (localStorage.getItem("categoryAssetCount") !== null) {
          let activeAssets = JSON.parse(
            base64.decode(localStorage.getItem("categoryAssetCount"))
          );
          console.log(activeAssets);
          console.log(assetsCountBySite);

          if (activeAssets.length > 0) {
            const totalAssetCount = activeAssets.reduce(
              (acc, item) => acc + item.assetCount,
              0
            );
            console.log(totalAssetCount);
            setAssetsCount(totalAssetCount);
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [localStorage.getItem("categoryAssetCount"), assetsCountBySite]);

  window.onload = function () {
    try {
      if (LoginUserGroup === "site_user") {
        fetchSubscriptionListSUAssetsCount();
      } else {
        if (localStorage.getItem("categoryAssetCount") !== null) {
          let activeAssets = JSON.parse(
            base64.decode(localStorage.getItem("categoryAssetCount"))
          );
          console.log(activeAssets);

          if (activeAssets.length > 0) {
            const totalAssetCount = activeAssets.reduce(
              (acc, item) => acc + item.assetCount,
              0
            );
            console.log(totalAssetCount);
            setAssetsCount(totalAssetCount);
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  console.log(assetsCount);
  const updatePageCard = (p) => {
    setCurrentPageCard(p);
    const to = countPerPageCard * p;
    const from = to - countPerPageCard;
    const data =
      value ||
        (state.state && state.state.site ? false : selectedSite) ||
        selectedLocation ||
        selectedBox
        ? filteredData
        : state.state && state.state.site && state.state.category
          ? assetDemoCard
          : assetDemoCard;
    setCollectionCard(cloneDeep(data.slice(from, to)));
  };
  // --- site user assets ---
  async function fetchSitesDetailsSU() {
    try {
      await client
        .graphql({
          query: queries.linkUsersAndSitesByUserdetailsID,
          variables: {
            userdetailsID: LoginUserDetails.id,
            limit: 100000,
          },
          authMode: "API_KEY",
        })
        .then(async (result) => {
          const sites = result.data.linkUsersAndSitesByUserdetailsID.items;
          if (sites.length > 0) {
            const promises = sites.map(async (item) => {
              return client.graphql({
                query: queries.getSiteDetails,
                variables: {
                  id: item.sitedetailsID,
                },
              });
            });
            Promise.all(promises)
              .then((results) => {
                console.log(results);
                let siteData = [];
                if (results.length > 0) {
                  results.map((site) => {
                    if (site.data.getSiteDetails !== null)
                      siteData.push(site.data.getSiteDetails);
                  });
                  if (siteData.length > 0) {
                    const siteOptions = [];
                    if (state.state) {
                      getAssetTemplate(siteData);
                    }
                    let activeSites = siteData.filter(
                      (item) => item.status === "Active"
                    );
                    console.log("activeSites", activeSites);
                    setSiteDetails(activeSites);
                    for (let site of activeSites) {
                      siteOptions.push({
                        value: site.id,
                        label:
                          site.siteName +
                          " " +
                          (site.unitNumber ? site.unitNumber : " "),
                      });
                    }
                    setSites(siteOptions);
                  }
                }
              })
              .catch((error) => {
                console.error(error);
              });
          } else {
            setSites([]);
            setIsLoading(false);
            setErrorMessage("No Assets to fetch");
          }
        });
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchSitesDetails() {
    try {
      console.log(limit);
      await client
        .graphql({
          query: queries.siteDetailsByAssetownerID,
          variables: {
            assetownerID: LoginUserDetails.id,
            limit: limit,
          },
          authMode: "API_KEY",
        })
        .then(async (result) => {
          const sites = result.data.siteDetailsByAssetownerID.items;
          let nextToken = result.data.siteDetailsByAssetownerID.nextToken;
          let siteData = [];
          if (sites.length > 0) {
            siteData.push(sites);
          }
          while (nextToken !== null) {
            await client
              .graphql({
                query: queries.siteDetailsByAssetownerID,
                variables: {
                  assetownerID: LoginUserDetails.id,
                  limit: limit,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              })
              .then((result) => {
                nextToken = result.data.siteDetailsByAssetownerID.nextToken;
                if (result.data.siteDetailsByAssetownerID.items.length > 0) {
                  siteData.push(result.data.siteDetailsByAssetownerID.items);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (siteData.length > 0) {
            let mergeSites = siteData.flat(1);
            let activeSites = mergeSites.filter(
              (item) => item.status === "Active"
            );
            const siteFilterArray = [];
            const siteOptions = [];
            let siteFilter = [];
            if (state.state && state.state.site) {
              siteFilter = activeSites.filter(
                (site) => site.id === state.state.site.id
              );
            }
            const globalsite = activeSites.filter(
              (site) => site.id === selectedSiteGlobal
            );
            console.log(globalsite);
            const dataFilter = selectedSiteGlobal ? globalsite : siteFilter;
            if (state.state) {
              dataFilter.length > 0
                ? getAssetTemplate(dataFilter)
                : getAssetTemplate(activeSites);
            }
            dataFilter.length > 0
              ? setSiteDetails(dataFilter)
              : setSiteDetails(activeSites);
            activeSites.map(async (item) => {
              siteFilterArray.push(item.id);
              siteOptions.push({
                value: item.id,
                label:
                  item.siteName +
                  " " +
                  (item.unitNumber ? item.unitNumber : " "),
              });
            });
            setSites(siteOptions);
          } else {
            setIsLoading(false);
            setErrorMessage("No Assets to fetch");
          }
        });
    } catch (error) {
      console.error(error);
    }
  }
  const getAssetTemplate = async (siteData) => {
    try {
      console.log(siteData);
      await client
        .graphql({
          query: queries.assetTemplatesByCategoriesID,
          variables: {
            categoriesID: state.state.category.id,
            filter: {
              status: {
                eq: "Active",
              },
            },
            limit: limit,
          },
          authMode: "API_KEY",
        })
        .then((result) => {
          console.log(result.data.assetTemplatesByCategoriesID.items);
          if (result.data.assetTemplatesByCategoriesID.items.length > 0) {
            setAssetTemplatData(result.data.assetTemplatesByCategoriesID.items);
            // getAssetsByTemplates(
            //   result.data.assetTemplatesByCategoriesID.items,
            //   siteData
            // );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    console.log("select global Site");
    async function fetchlistDistributionBoxSU() {
      try {
        let sites = JSON.parse(base64.decode(localStorage.getItem("site")));
        let activeSites = sites.filter((item) => item.status === "Active");
        if (activeSites.length > 0) {
          let nextToken = "";
          let db = [];
          for (let site of activeSites) {
            const DBResult = await client.graphql({
              query: queries.distributionBoxesBySitedetailsID,
              variables: {
                sitedetailsID: site.id,
              },
              authMode: "API_KEY",
            });
            let assetDatas =
              DBResult.data.distributionBoxesBySitedetailsID.items;
            nextToken =
              DBResult.data.distributionBoxesBySitedetailsID.nextToken;
            if (
              DBResult.data.distributionBoxesBySitedetailsID.items.length > 0
            ) {
              db.push(assetDatas);
            }
            while (nextToken !== null) {
              const DBResult1 = await client.graphql({
                query: queries.distributionBoxesBySitedetailsID,
                variables: {
                  sitedetailsID: site.id,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              });
              nextToken =
                DBResult1.data.distributionBoxesBySitedetailsID.nextToken;
              if (
                DBResult1.data.distributionBoxesBySitedetailsID.items.length > 0
              ) {
                db.push(DBResult1.data.distributionBoxesBySitedetailsID.items);
              }
            }
          }
          if (db.length > 0) {
            const mergeDB = db.flat(1);
            console.log(mergeDB);
            setDistributionBoxList(mergeDB);
            setDistributionBoxfilter(mergeDB);
            SiteDBData.setSiteDBData(mergeDB);
            localStorage.setItem(
              "siteDB",
              base64.encode(JSON.stringify(mergeDB))
            );
            optionsDB.push({ value: "", label: "All Distribution Board" });
            mergeDB.map(async (item) => {
              optionsDB.push({
                value: item.id,
                label: item.distributionBoxName,
              });
            });
          }
        } else {
          setDistributionBoxList([]);
          setDistributionBoxfilter([]);
        }
      } catch (error) {
        console.error(error);
      }
    }
    if (localStorage.getItem("site") === null && sites.length === 0) {
      LoginUserGroup === "site_user" || LoginUserGroup === "electrician"
        ? fetchSitesDetailsSU()
        : fetchSitesDetails();
    } else {
      let sites = JSON.parse(base64.decode(localStorage.getItem("site")));
      let activeSites = sites.filter((item) => item.status === "Active");
      setSiteDetails(activeSites);
      let siteOptions = [];
      for (let site of activeSites) {
        siteOptions.push({
          value: site.id,
          label:
            site.siteName + " " + (site.unitNumber ? site.unitNumber : " "),
        });
      }
      setSites(siteOptions);
    }
    if (
      localStorage.getItem("siteDB") === null &&
      (distributionBoxList.length === 0 || distributionBoxFilter.length === 0)
    ) {
      fetchlistDistributionBoxSU();
    } else {
      let DB = JSON.parse(base64.decode(localStorage.getItem("siteDB")));
      setDistributionBoxList(DB);
      setDistributionBoxfilter(DB);
      optionsDB.push({ value: "", label: "All Distribution Board" });
      DB.map(async (item) => {
        optionsDB.push({
          value: item.id,
          label: item.distributionBoxName,
        });
      });
    }
  }, []);
  useEffect(() => {
    if (selectedSiteGlobal) {
      console.log("selectedSiteGlobal onchange", selectedSiteGlobal);
      setDistributionBoxfilter(
        distributionBoxList.filter((item) => {
          return item.sitedetailsID === selectedSiteGlobal;
        })
      );
      getAssets();
    } else {
      setDistributionBoxfilter(distributionBoxList);
    }
  }, [selectedSiteGlobal]);
  useEffect(() => {
    if (selectedSite !== "") {
      console.log("selected Site", selectedSite);
      setDistributionBoxfilter(
        distributionBoxList.filter((item) => {
          return item.sitedetailsID === selectedSite;
        })
      );
    } else {
      setDistributionBoxfilter(distributionBoxList);
    }
  }, [selectedSite]);
  useEffect(() => {
    if (siteDetails.length > 0) {
      if (state.state && state.state.site && state.state.site.id) {
        setSelectedSite(state.state.site.id);
        searchAssetDataSiteView();
      } else {
        getAssets();
      }
    }
  }, [siteDetails]);
  async function getAssets() {
    if (isReset) {
      setHasMore(true);
      isReset = false;
      if (
        selectedSiteGlobal ||
        (state.state && state.state.site && state.state.site.id)
      ) {
        if (assetTemplatData.length > 0) {
          try {
            let assets = [];
            let count = 0;
            let nextTokens = "";
            for (let item of assetTemplatData) {
              try {
                count = count + 1;
                let assetSiteTemp =
                  item.id + "_" + selectedSiteGlobal
                    ? selectedSiteGlobal
                    : state.state.site.id;
                const assetsData = await client.graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: assetSiteTemp,
                    limit: 800,
                    filter: {
                      status: {
                        ne: "delete",
                      },
                    },
                  },
                  authMode: "API_KEY",
                });

                console.log(
                  "template->" + item.id + "<->" + item.templatename,
                  assetsData
                );
                const data =
                  assetsData.data.assetsByAssettemplateSiteIDAndAssetNum;
                nextTokens = data.nextToken;
                if (nextTokens !== null) {
                  assetRecordsRef.current.push({
                    assettemplsiteid: assetSiteTemp,
                    nextToken: nextTokens,
                  });
                }
                console.log(assetRecordsRef.current);
                if (data.items.length > 0) {
                  assets.push(data.items);
                }

                if (count === assetTemplatData.length) {
                  const mergeAssets = assets.flat(1);
                  setNextToken(nextTokens);
                  setItems((prevItems) => [...prevItems, ...mergeAssets]);
                  if (nextTokens === null) {
                    console.log(siteIndex);
                    setSiteIndex(siteIndex + 1);
                  } else {
                    setHasMore(!nextTokens);
                  }
                }
              } catch (error) {
                console.error("Error fetching data:", error);
              }
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        }
      } else {
        let siteId = siteDetails[siteIndex].id;
        if (assetTemplatData.length > 0) {
          try {
            let assets = [];
            let count = 0;
            let nextTokens = "";
            for (let item of assetTemplatData) {
              try {
                count = count + 1;
                let assetSiteTemp = item.id + "_" + siteId;
                const assetsData = await client.graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: assetSiteTemp,
                    limit: 800,
                    filter: {
                      status: {
                        ne: "delete",
                      },
                    },
                  },
                  authMode: "API_KEY",
                });

                console.log(
                  "template->" + item.id + "<->" + item.templatename,
                  assetsData
                );
                const data =
                  assetsData.data.assetsByAssettemplateSiteIDAndAssetNum;
                nextTokens = data.nextToken;
                if (nextTokens !== null) {
                  assetRecordsRef.current.push({
                    assettemplsiteid: assetSiteTemp,
                    nextToken: nextTokens,
                  });
                }
                console.log(assetRecordsRef.current);
                if (data.items.length > 0) {
                  assets.push(data.items);
                }

                if (count === assetTemplatData.length) {
                  const mergeAssets = assets.flat(1);
                  setNextToken(nextTokens);
                  setItems((prevItems) => [...prevItems, ...mergeAssets]);

                  if (nextTokens === null) {
                    console.log(siteIndex);
                    setSiteIndex(siteIndex + 1);
                  } else {
                    setHasMore(!nextTokens);
                  }
                }
              } catch (error) {
                console.error("Error fetching data:", error);
              }
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        }
      }
      setIsLoading(false);
    } else {
      if (selectedSiteGlobal) {
        console.log("selectedSiteGlobal", selectedSiteGlobal);
        if (assetTemplatData.length > 0) {
          if (!hasMore) return;

          try {
            let assets = [];
            let count = 0;
            let nextTokens = "";
            for (let item of assetTemplatData) {
              count = count + 1;
              let assetSiteTemp = item.id + "_" + selectedSiteGlobal;
              const assetsData = await client.graphql({
                query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                variables: {
                  assettemplateSiteID: assetSiteTemp,
                  limit: 800,
                  filter: {
                    status: {
                      ne: "delete",
                    },
                  },
                },
                authMode: "API_KEY",
              });
              console.log(assetsData);
              const data =
                assetsData.data.assetsByAssettemplateSiteIDAndAssetNum;
              nextTokens = data.nextToken;
              if (nextTokens !== null) {
                assetRecordsRef.current.push({
                  assettemplsiteid: assetSiteTemp,
                  nextToken: nextTokens,
                });
              }
              console.log(siteIndex, assetRecordsRef.current);
              if (data.items.length > 0) {
                assets.push(data.items);
              }
              if (count === assetTemplatData.length) {
                const mergeAssets = assets.flat(1);
                setNextToken(nextTokens);
                setItems((prevItems) => [...prevItems, ...mergeAssets]);

                setHasMore(!nextTokens);
              }
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        } else {
          const assetTemplateResults = await client.graphql({
            query: queries.assetTemplatesByCategoriesID,
            variables: {
              categoriesID: catid,
              filter: {
                status: {
                  eq: "Active",
                },
              },
              limit: 800,
            },
            authMode: "API_KEY",
          });

          if (
            assetTemplateResults.data.assetTemplatesByCategoriesID.items
              .length > 0
          ) {
            setAssetTemplatData(
              assetTemplateResults.data.assetTemplatesByCategoriesID.items
            );
            if (!hasMore) return;

            try {
              let assets = [];
              let count = 0;
              let nextTokens = "";
              for (let item of assetTemplateResults.data
                .assetTemplatesByCategoriesID.items) {
                count = count + 1;
                let assetSiteTemp = item.id + "_" + selectedSiteGlobal;
                var assetResults = await client.graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: assetSiteTemp,
                    limit: 800,
                    filter: {
                      status: {
                        ne: "delete",
                      },
                    },
                  },
                  authMode: "API_KEY",
                });

                console.log(assetResults);
                const assetData =
                  assetResults.data.assetsByAssettemplateSiteIDAndAssetNum;
                nextTokens = assetData.nextToken;
                if (assetData.items.length > 0) {
                  assets.push(assetData.items);
                }

                if (nextTokens !== null) {
                  assetRecordsRef.current.push({
                    assettemplsiteid: assetSiteTemp,
                    nextToken: nextTokens,
                  });
                }
                console.log(siteIndex, assetRecordsRef.current);
                if (
                  count ===
                  assetTemplateResults.data.assetTemplatesByCategoriesID.items
                    .length
                ) {
                  console.log(assets);
                  const mergeAssets = assets.flat(1);
                  setNextToken(nextTokens);
                  setItems((prevItems) => [...prevItems, ...mergeAssets]);

                  setHasMore(!nextTokens);
                }
              }
            } catch (error) {
              console.error("Error fetching asset data:", error);
            }
          }
        }
      } else {
        if (siteIndex < siteDetails.length) {
          let siteId = siteDetails[siteIndex].id;
          if (assetTemplatData.length > 0) {
            if (!hasMore) return;
            try {
              let assets = [];
              let count = 0;
              let nextTokens = "";
              for (let item of assetTemplatData) {
                try {
                  count = count + 1;
                  let assetSiteTemp = item.id + "_" + siteId;
                  const assetsData = await client.graphql({
                    query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                    variables: {
                      assettemplateSiteID: assetSiteTemp,
                      limit: 800,
                      filter: {
                        status: {
                          ne: "delete",
                        },
                      },
                    },
                    authMode: "API_KEY",
                  });

                  const data =
                    assetsData.data.assetsByAssettemplateSiteIDAndAssetNum;
                  console.log(
                    "template->" + item.id + "<->" + item.templatename,
                    data.nextToken
                  );
                  nextTokens = data.nextToken;
                  if (nextTokens !== null) {
                    assetRecordsRef.current.push({
                      assettemplsiteid: assetSiteTemp,
                      nextToken: data.nextToken,
                    });
                  }
                  console.log(siteIndex, assetRecordsRef.current);
                  if (data.items.length > 0) {
                    assets.push(data.items);
                  }

                  if (count === assetTemplatData.length) {
                    const mergeAssets = assets.flat(1);
                    setNextToken(nextTokens);
                    setItems((prevItems) => [...prevItems, ...mergeAssets]);
                    console.log("hasMore", hasMore);
                    if (nextTokens === null) {
                      console.log(siteIndex);
                      setSiteIndex(siteIndex + 1);
                    } else {
                      setHasMore(!!nextTokens);
                    }
                  }
                } catch (error) {
                  console.error("Error fetching data:", error);
                }
              }
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          } else {
            const assetTemplateResults = await client.graphql({
              query: queries.assetTemplatesByCategoriesID,
              variables: {
                categoriesID: catid,
                filter: {
                  status: {
                    eq: "Active",
                  },
                },
                limit: 800,
              },
              authMode: "API_KEY",
            });

            if (
              assetTemplateResults.data.assetTemplatesByCategoriesID.items
                .length > 0
            ) {
              setAssetTemplatData(
                assetTemplateResults.data.assetTemplatesByCategoriesID.items
              );
              if (!hasMore) return;

              try {
                let assets = [];
                let count = 0;
                let nextTokens = "";
                for (let item of assetTemplateResults.data
                  .assetTemplatesByCategoriesID.items) {
                  try {
                    count = count + 1;
                    let siteId = siteDetails[siteIndex].id;
                    let assetSiteTemp = item.id + "_" + siteId;
                    console.log(assetSiteTemp);

                    var assetResults = await client.graphql({
                      query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                      variables: {
                        assettemplateSiteID: assetSiteTemp,
                        limit: 800,
                        filter: {
                          status: {
                            ne: "delete",
                          },
                        },
                      },
                      authMode: "API_KEY",
                    });
                    const assetData =
                      assetResults.data.assetsByAssettemplateSiteIDAndAssetNum;
                    console.log(
                      "template->" + item.id + "<->" + item.templatename,
                      assetData.nextToken
                    );
                    nextTokens = assetData.nextToken;
                    if (nextTokens !== null) {
                      assetRecordsRef.current.push({
                        assettemplsiteid: assetSiteTemp,
                        nextToken: nextTokens,
                      });
                    }
                    console.log(siteIndex, assetRecordsRef.current);
                    if (assetData.items.length > 0) {
                      assets.push(assetData.items);
                    }
                    if (
                      count ===
                      assetTemplateResults.data.assetTemplatesByCategoriesID
                        .items.length
                    ) {
                      const mergeAssets = assets.flat(1);
                      setNextToken(nextTokens);
                      setItems((prevItems) => [...prevItems, ...mergeAssets]);
                      if (nextTokens === null) {
                        console.log(siteIndex);
                        setSiteIndex(siteIndex + 1);
                      } else {
                        console.log("hasmore");
                        setHasMore(!!nextTokens);
                      }
                    }
                  } catch (error) {
                    console.error("Error fetching data:", error);
                  }
                }
              } catch (error) {
                console.error("Error fetching asset data:", error);
              }
            }
          }
        } else {
          console.log(
            "site Index",
            siteIndex + ",length " + siteDetails.length
          );
          if (assetRecordsRef.current.length === 0) {
            setHasMore(!hasMore);
          }
        }
      }
    }
    setIsLoading(false);
  }
  async function getAssetsLoop() {
    // setHasMore(true);
    console.log(assetRecordsRef.current);
    try {
      // if (siteIndex === siteDetails.length) {
      if (assetRecordsRef.current.length > 0) {
        let assets = [];
        let count = 0;
        let allNextTokensNull = true;
        for (let item of assetRecordsRef.current) {
          if (item.nextToken !== null) {
            try {
              count += 1;
              let assetSiteTemp = item.assettemplsiteid;

              const assetsData = await client.graphql({
                query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                variables: {
                  assettemplateSiteID: assetSiteTemp,
                  limit: 800,
                  filter: {
                    status: {
                      ne: "delete",
                    },
                  },
                  nextToken: item.nextToken,
                },
                authMode: "API_KEY",
              });

              const data =
                assetsData.data.assetsByAssettemplateSiteIDAndAssetNum;
              const nextTokens = data.nextToken;

              if (data.items.length > 0) {
                assets.push(data.items);
              }

              const existingIndex = assetRecordsRef.current.findIndex(
                (record) => record.assettemplsiteid === item.assettemplsiteid
              );
              if (existingIndex !== -1) {
                assetRecordsRef.current[existingIndex].nextToken = nextTokens;
              } else {
                assetRecordsRef.current.push({
                  assettemplsiteid: item.assettemplsiteid,
                  nextToken: nextTokens,
                });
              }
              if (nextTokens !== null) {
                allNextTokensNull = false;
              }

              if (count === assetRecordsRef.current.length) {
                const mergeAssets = assets.flat(1);
                setNextToken(nextTokens);
                setItems((prevItems) => [...prevItems, ...mergeAssets]);
                let removeData = assetRecordsRef.current;
                removeData.map((item, i) => {
                  if (item.nextToken === null) {
                    assetRecordsRef.current.splice(i, 1);
                  }
                });
                if (nextTokens === null) {
                  setSiteIndex(siteIndex + 1);
                }
                if (siteIndex === siteDetails.length) {
                  setHasMore(assetRecordsRef.current.length > 0);
                } else {
                  setHasMore(true);
                }
              }
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          }
        }
      } else {
        getAssets();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  async function getAssetsLoopSearch() {
    // setHasMore(true);
    console.log(assetRecordsSearchRef.current);
    console.log(assetRecordsSearchDBRef.current);
    if (assetRecordsSearchRef.current.length > 0) {
      try {
        let assets = [];
        let count = 0;
        for (let item of assetRecordsSearchRef.current) {
          if (item.nextToken !== null) {
            try {
              count += 1;
              let assetSiteTemp = item.assettemplsiteid;
              let assetsData = [];
              if (selectedBox) {
                assetsData = await client.graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: assetSiteTemp,
                    limit: 800,
                    filter: {
                      distributionboxID: { eq: selectedBox },
                      status: {
                        ne: "delete",
                      },
                    },
                    nextToken: item.nextToken,
                  },
                  authMode: "API_KEY",
                });
              } else {
                assetsData = await client.graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: assetSiteTemp,
                    limit: 800,
                    filter: {
                      status: {
                        ne: "delete",
                      },
                    },
                    nextToken: item.nextToken,
                  },
                  authMode: "API_KEY",
                });
              }
              const data =
                assetsData.data.assetsByAssettemplateSiteIDAndAssetNum;
              const nextTokens = data.nextToken;
              if (data.items.length > 0) {
                assets.push(data.items);
              }
              const existingIndex = assetRecordsSearchRef.current.findIndex(
                (record) => record.assettemplsiteid === item.assettemplsiteid
              );
              if (nextTokens !== null) {
                if (existingIndex !== -1) {
                  assetRecordsSearchRef.current[existingIndex].nextToken =
                    nextTokens;
                } else {
                  assetRecordsSearchRef.current.push({
                    assettemplsiteid: item.assettemplsiteid,
                    nextToken: nextTokens,
                  });
                }
              } else {
                if (existingIndex !== -1) {
                  assetRecordsSearchRef.current.splice(existingIndex, 1);
                }
              }
              if (count === assetRecordsSearchRef.current.length) {
                let removeData = assetRecordsSearchRef.current;
                removeData.map((item, i) => {
                  if (item.nextToken === null) {
                    assetRecordsSearchRef.current.splice(i, 1);
                  }
                });
                const mergeAssets = assets.flat(1);
                setNextToken(nextTokens);
                setItems((prevItems) => [...prevItems, ...mergeAssets]);
                assetRecordsSearchRef.current.length === 0
                  ? setHasMore(false)
                  : setHasMore(true);
              }
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else if (assetRecordsSearchDBRef.current.length > 0) {
      try {
        let assets = [];
        let count = 0;
        for (let item of assetRecordsSearchDBRef.current) {
          if (
            item.nextToken !== null &&
            selectedBox === item.distributionboxID
          ) {
            try {
              count += 1;
              let assetSiteTemp = item.assettemplsiteid;
              let assetsData = await client.graphql({
                query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                variables: {
                  assettemplateSiteID: assetSiteTemp,
                  limit: 800,
                  filter: {
                    distributionboxID: { eq: selectedBox },
                    status: {
                      ne: "delete",
                    },
                  },
                  nextToken: item.nextToken,
                },
                authMode: "API_KEY",
              });
              const data =
                assetsData.data.assetsByAssettemplateSiteIDAndAssetNum;
              const nextTokens = data.nextToken;
              if (data.items.length > 0) {
                assets.push(data.items);
              }
              const existingIndex = assetRecordsSearchDBRef.current.findIndex(
                (record) =>
                  record.assettemplsiteid === item.assettemplsiteid &&
                  selectedBox === record.distributionboxID
              );
              if (nextTokens !== null) {
                if (existingIndex !== -1) {
                  assetRecordsSearchDBRef.current[existingIndex].nextToken =
                    nextTokens;
                } else {
                  assetRecordsSearchDBRef.current.push({
                    assettemplsiteid: item.assettemplsiteid,
                    nextToken: nextTokens,
                    distributionboxID: selectedBox,
                  });
                }
              } else {
                if (existingIndex !== -1) {
                  assetRecordsSearchDBRef.current[existingIndex].nextToken =
                    nextTokens;
                }
              }
              if (count === assetRecordsSearchDBRef.current.length) {
                let removeData = assetRecordsSearchDBRef.current;
                removeData.map((item, i) => {
                  if (item.nextToken === null) {
                    assetRecordsSearchDBRef.current.splice(i, 1);
                  }
                });
                const mergeAssets = assets.flat(1);
                setNextToken(nextTokens);
                setItems((prevItems) => [...prevItems, ...mergeAssets]);
                assetRecordsSearchDBRef.current.length === 0
                  ? setHasMore(false)
                  : setHasMore(true);
              }
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  }
  const handleSearch = () => {
    try {
      isDBChange = false;
      assetRecordsSearchDBRef.current = [];
      setIsSearch(true);
      setSearchAssets(true);
      setSiteIndex(0);
    } catch (error) {
      console.error("asset search", error);
    }
  };
  useEffect(() => {
    console.log(items);
    if (items.length > 0) {
      if (fullItems.length !== items.length) {
        if (items.length > 0 && hasMore) {
          if (state.state && state.state.site && state.state.site.id) {
            console.log(state.state.site.id);
            const assets = items.filter(
              (item) => item.sitedetailsID === state.state.site.id
            );
            const assetsFilter =
              state.state && state.state.site && state.state.site.id
                ? assets
                : items;
            console.log([...new Set(assetsFilter)]);
            setFullItems(assetsFilter);
          }
          const seen = new Set();
          const uniqueArr = items.filter((item) => {
            if (seen.has(item["id"])) {
              return false;
            } else {
              seen.add(item["id"]);
              return true;
            }
          });
          console.log("uniqueArr", uniqueArr);
          setFullItems(uniqueArr);
          console.log("fullItems " + siteIndex, fullItems);
        } else if (items.length > 0 && !hasMore) {
          if (state.state && state.state.site && state.state.site.id) {
            console.log(state.state.site.id);
            const assets = items.filter(
              (item) => item.sitedetailsID === state.state.site.id
            );
            const assetsFilter =
              state.state && state.state.site && state.state.site.id
                ? assets
                : items;
            console.log([...new Set(assetsFilter)]);
            setFullItems(assetsFilter);
          }
          const seen = new Set();
          const uniqueArr = items.filter((item) => {
            if (seen.has(item["id"])) {
              return false;
            } else {
              seen.add(item["id"]);
              return true;
            }
          });
          console.log("uniqueArr", uniqueArr);
          setFullItems(uniqueArr);
          console.log("fullItems " + siteIndex, fullItems);
        } else if (siteIndex < siteDetails.length && items.length === 0) {
          getAssets();
        }
      } else {
        if (isSearch) {
          if (items.length > 0 && hasMore) {
            if (state.state && state.state.site && state.state.site.id) {
              console.log(state.state.site.id);
              const assets = items.filter(
                (item) => item.sitedetailsID === state.state.site.id
              );
              const assetsFilter =
                state.state && state.state.site && state.state.site.id
                  ? assets
                  : items;
              setFullItems(assetsFilter);
            }
            const seen = new Set();
            const uniqueArr = items.filter((item) => {
              if (seen.has(item["id"])) {
                return false;
              } else {
                seen.add(item["id"]);
                return true;
              }
            });
            console.log("uniqueArr", uniqueArr);
            setFullItems(uniqueArr);
            console.log("fullItems " + siteIndex, fullItems);
          } else if (siteIndex < siteDetails.length && items.length === 0) {
            getAssets();
          }
        } else if (
          siteIndex < siteDetails.length && items.length > 800 &&
          fullItems.length === items.length
        ) {
          getAssets();
        } else if (
          siteIndex === siteDetails.length && items.length > 800 &&
          fullItems.length === items.length
        ) {
          getAssetsLoop();
        }
      }
    } else if (isSearch && items.length === 0) {
      setFullItems([]);
      setHasMore(false);
    }
  }, [items]);
  useEffect(() => {
    if (siteIndex > 0 && items.length < 800) {
      getAssets();
    }
  }, [siteIndex]);
  useEffect(() => {
    if (isReset) {
      state.state && state.state.site && state.state.site.id
        ? searchAssetDataSiteView()
        : getAssets();
    }
  }, [isReset]);
  async function searchAssetData() {
    // setHasMore(true);
    console.log(assetRecordsSearchRef.current);
    console.log(assetRecordsSearchDBRef.current);
    if (!isDBChange) {
      if (isSearch && !isSecoundSearch) {
        setItems([]);
      }
      let siteId = selectedSite;
      if (assetTemplatData.length > 0) {
        try {
          let assets = [];
          let count = 0;
          let nextTokens = "";
          for (let item of assetTemplatData) {
            count = count + 1;
            let assetSiteTemp = item.id + "_" + siteId;
            console.log(assetSiteTemp);
            console.log(selectedBox);
            let assetsData = [];
            if (selectedBox) {
              assetsData = await client.graphql({
                query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                variables: {
                  assettemplateSiteID: assetSiteTemp,
                  limit: 800,
                  filter: {
                    distributionboxID: { eq: selectedBox },
                    status: {
                      ne: "delete",
                    },
                  },
                },
                authMode: "API_KEY",
              });
            } else {
              assetsData = await client.graphql({
                query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                variables: {
                  assettemplateSiteID: assetSiteTemp,
                  limit: 800,
                  filter: {
                    status: {
                      ne: "delete",
                    },
                  },
                },
                authMode: "API_KEY",
              });
            }
            console.log(assetsData);
            const data = assetsData.data.assetsByAssettemplateSiteIDAndAssetNum;
            nextTokens = data.nextToken;
            if (nextTokens !== null) {
              if (selectedBox) {
                assetRecordsSearchDBRef.current.push({
                  assettemplsiteid: assetSiteTemp,
                  nextToken: nextTokens,
                  distributionboxID: selectedBox,
                });
              } else {
                assetRecordsSearchRef.current.push({
                  assettemplsiteid: assetSiteTemp,
                  nextToken: nextTokens,
                });
              }
            }
            if (data.items.length > 0) {
              assets.push(data.items);
            }

            console.log(assetRecordsSearchRef.current);
            if (count === assetTemplatData.length) {
              const mergeAssets = assets.flat(1);
              setNextToken(nextTokens);
              setItems((prevItems) => [...prevItems, ...mergeAssets]);
              if (selectedBox) {
                assetRecordsSearchDBRef.current.length === 0
                  ? setHasMore(false)
                  : setHasMore(true);
              } else {
                assetRecordsSearchRef.current.length === 0
                  ? setHasMore(false)
                  : setHasMore(true);
              }
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        const assetTemplateResults = await client.graphql({
          query: queries.assetTemplatesByCategoriesID,
          variables: {
            categoriesID: catid,
            filter: {
              status: {
                eq: "Active",
              },
            },
            limit: 800,
          },
          authMode: "API_KEY",
        });

        if (
          assetTemplateResults.data.assetTemplatesByCategoriesID.items.length >
          0
        ) {
          setAssetTemplatData(
            assetTemplateResults.data.assetTemplatesByCategoriesID.items
          );
          // if (isLoading || !hasMore) return;
          //
          try {
            let assets = [];
            let count = 0;
            let nextTokens = "";

            for (let item of assetTemplateResults.data
              .assetTemplatesByCategoriesID.items) {
              var assetResults = [];
              count = count + 1;
              let assetSiteTemp = item.id + "_" + siteId;
              console.log(selectedBox);
              if (selectedBox) {
                assetResults = await client.graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: assetSiteTemp,
                    limit: 800,
                    filter: {
                      distributionboxID: { eq: selectedBox },
                      status: {
                        ne: "delete",
                      },
                    },
                  },
                  authMode: "API_KEY",
                });
              } else {
                assetResults = await client.graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: assetSiteTemp,
                    limit: 800,
                    filter: {
                      status: {
                        ne: "delete",
                      },
                    },
                  },
                  authMode: "API_KEY",
                });
              }

              console.log(assetResults);
              const assetData =
                assetResults.data.assetsByAssettemplateSiteIDAndAssetNum;
              nextTokens = assetData.nextToken;
              if (nextTokens !== null) {
                if (selectedBox) {
                  assetRecordsSearchDBRef.current.push({
                    assettemplsiteid: assetSiteTemp,
                    nextToken: nextTokens,
                    distributionboxID: selectedBox,
                  });
                } else {
                  assetRecordsSearchRef.current.push({
                    assettemplsiteid: assetSiteTemp,
                    nextToken: nextTokens,
                  });
                }
              }
              if (assetData.items.length > 0) {
                assets.push(assetData.items);
              }

              console.log(assetRecordsSearchRef.current);
              if (
                count ===
                assetTemplateResults.data.assetTemplatesByCategoriesID.items
                  .length
              ) {
                const mergeAssets = assets.flat(1);
                //setNextToken(nextTokens);
                setItems((prevItems) => [...prevItems, ...mergeAssets]);
                if (nextTokens === null) {
                  setHasMore(!!nextTokens);
                }
              }
            }
          } catch (error) {
            console.error("Error fetching asset data:", error);
          }
        }
      }
    }
  }
  async function searchAssetDataSiteView() {
    setHasMore(true);

    let siteId = state.state.site.id;
    console.log(siteId);
    console.log(assetTemplatData);
    if (isReset) {
      isReset = false;
      setItems([]);
      setFullAssets([]);
      if (state.state && state.state.site && state.state.site.id) {
        if (assetTemplatData.length > 0) {
          if (isLoading || !hasMore) return;

          try {
            let assets = [];
            let count = 0;
            let nextTokens = "";
            //siteDetails.map(async (site) => {
            for (let item of assetTemplatData) {
              count = count + 1;
              let assetSiteTemp = item.id + "_" + state.state.site.id;
              const assetsData = await client.graphql({
                query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                variables: {
                  assettemplateSiteID: assetSiteTemp,
                  limit: 800,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              });
              console.log(assetsData);
              const data =
                assetsData.data.assetsByAssettemplateSiteIDAndAssetNum;
              nextTokens = data.nextToken;
              if (data.items.length > 0) {
                assets.push(data.items);
              }
              if (count === assetTemplatData.length) {
                const mergeAssets = assets.flat(1);
                setNextToken(nextTokens);
                setItems((prevItems) => [...prevItems, ...mergeAssets]);

                setHasMore(!nextTokens);
              }
            }
            //});
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        } else {
          const assetTemplateResults = await client.graphql({
            query: queries.assetTemplatesByCategoriesID,
            variables: {
              categoriesID: catid,
              filter: {
                status: {
                  eq: "Active",
                },
              },
              limit: 800,
            },
            authMode: "API_KEY",
          });

          if (
            assetTemplateResults.data.assetTemplatesByCategoriesID.items
              .length > 0
          ) {
            setAssetTemplatData(
              assetTemplateResults.data.assetTemplatesByCategoriesID.items
            );
            if (isLoading || !hasMore) return;

            try {
              let assets = [];
              let count = 0;
              let nextTokens = "";
              for (let item of assetTemplateResults.data
                .assetTemplatesByCategoriesID.items) {
                count = count + 1;
                let assetSiteTemp = item.id + "_" + state.state.site.id;
                var assetResults = await client.graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: assetSiteTemp,
                    limit: 800,
                    nextToken: nextToken,
                  },
                  authMode: "API_KEY",
                });

                console.log(assetResults);
                const assetData =
                  assetResults.data.assetsByAssettemplateSiteIDAndAssetNum;
                nextToken = assetData.nextToken;
                if (assetData.items.length > 0) {
                  assets.push(assetData.items);
                }
                if (
                  count ===
                  assetTemplateResults.data.assetTemplatesByCategoriesID.items
                    .length
                ) {
                  console.log(assets);
                  const mergeAssets = assets.flat(1);
                  setNextToken(nextTokens);
                  setItems((prevItems) => [...prevItems, ...mergeAssets]);

                  setHasMore(!nextTokens);
                }
              }
            } catch (error) {
              console.error("Error fetching asset data:", error);
            }
          }
        }
      } else {
        setNextToken(null);
        setItems((prevItems) => [...prevItems, ...[]]);

        setHasMore(!hasMore);
      }
    } else {
      if (assetTemplatData.length > 0) {
        try {
          let assets = [];
          let count = 0;
          let nextTokens = "";
          for (let item of assetTemplatData) {
            count = count + 1;
            let assetSiteTemp = item.id + "_" + siteId;
            console.log(assetSiteTemp);
            let assetsData = [];
            if (selectedBox) {
              assetsData = await client.graphql({
                query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                variables: {
                  assettemplateSiteID: assetSiteTemp,
                  limit: 800,
                  filter: {
                    distributionboxID: { eq: selectedBox },
                  },
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              });
            } else {
              assetsData = await client.graphql({
                query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                variables: {
                  assettemplateSiteID: assetSiteTemp,
                  limit: 800,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              });
            }
            console.log(assetsData);
            const data = assetsData.data.assetsByAssettemplateSiteIDAndAssetNum;
            nextTokens = data.nextToken;
            if (data.items.length > 0) {
              assets.push(data.items);
            }
            if (count === assetTemplatData.length) {
              const mergeAssets = assets.flat(1);
              setNextToken(nextTokens);
              setItems((prevItems) => [...prevItems, ...mergeAssets]);

              setHasMore(!nextTokens);
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        const assetTemplateResults = await client.graphql({
          query: queries.assetTemplatesByCategoriesID,
          variables: {
            categoriesID: state.state.category.id,
            filter: {
              status: {
                eq: "Active",
              },
            },
            limit: 800,
          },
          authMode: "API_KEY",
        });

        if (
          assetTemplateResults.data.assetTemplatesByCategoriesID.items.length >
          0
        ) {
          setAssetTemplatData(
            assetTemplateResults.data.assetTemplatesByCategoriesID.items
          );
          try {
            let assets = [];
            let count = 0;
            let nextTokens = "";
            for (let item of assetTemplateResults.data
              .assetTemplatesByCategoriesID.items) {
              count = count + 1;
              let assetSiteTemp = item.id + "_" + siteId;
              let assetResults = [];
              if (selectedBox) {
                assetResults = await client.graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: assetSiteTemp,
                    limit: 800,
                    filter: {
                      distributionboxID: { eq: selectedBox },
                    },
                    nextToken: nextToken,
                  },
                  authMode: "API_KEY",
                });
              } else {
                assetResults = await client.graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: assetSiteTemp,
                    limit: 800,
                    nextToken: nextToken,
                  },
                  authMode: "API_KEY",
                });
              }

              console.log(assetResults);
              const assetData =
                assetResults.data.assetsByAssettemplateSiteIDAndAssetNum;
              nextToken = assetData.nextToken;
              if (assetData.items.length > 0) {
                assets.push(assetData.items);
              }
              if (
                count ===
                assetTemplateResults.data.assetTemplatesByCategoriesID.items
                  .length
              ) {
                console.log(assets);
                const mergeAssets = assets.flat(1);
                console.log(mergeAssets);
                setNextToken(nextTokens);
                setItems((prevItems) => [...prevItems, ...mergeAssets]);

                setHasMore(!nextTokens);
              }
            }
          } catch (error) {
            console.error("Error fetching asset data:", error);
          }
        }
      }
    }
  }
  useEffect(() => {
    if (isSearch) {
      searchAssetData();
    }
  }, [isSearch]);
  const activeInactive = () => {
    setIsActive(!isActive);
    console.log(isActive);
    console.log(activedata);
    const data = isActive
      ? activedata.filter((item) => item.status === "Inactive")
      : activedata.filter((item) => item.status === "Active");
    console.log(data);
    if (
      value ||
      selectedSite ||
      selectedBox ||
      (selectedLocation && selectedLocation.trim())
    ) {
      let filteredAssets = data;
      if (value) {
        filteredAssets = filteredAssets.filter((asset) => {
          let siteName = getSiteName(asset.sitedetailsID);
          let dbName =
            asset.distributionBox !== null &&
              asset.distributionBox !== undefined
              ? getDBName(asset.distributionboxID)
              : "";
          let assetTemp = assetTemplatData.filter((item) => {
            return (item.id = asset.assettemplateID);
          });
          let assetfittingnumbers =
            asset.fittingNumber.match(/^[0-9]+$/) !== null
              ? asset.fittingNumber
              : asset.fittingNumber.toLowerCase();
          let searchfittingnumbers =
            value.match(/^[0-9]+$/) !== null ? value : value.toLowerCase();
          return (
            (asset.assetModel !== null &&
              asset.assetModel.toLowerCase().includes(value.toLowerCase())) ||
            (dbName !== "" &&
              dbName.toLowerCase().includes(value.toLowerCase())) ||
            siteName.toLowerCase().includes(value.toLowerCase()) ||
            (assetfittingnumbers !== null &&
              assetfittingnumbers.includes(searchfittingnumbers)) ||
            moment(asset.createdAt)
              .format("DD/MM/YYYY")
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            (assetTemp.length > 0 &&
              assetTemp[0].templatename
                .toLowerCase()
                .includes(value.toLowerCase()))
          );
        });
      }
      if (selectedSite) {
        filteredAssets = filteredAssets.filter(
          (asset) => asset.siteId === selectedSite
        );
        setDistributionBoxfilter(
          distributionBoxList.filter(
            (item) => item.sitedetailsID === selectedSite
          )
        );
      } else {
        setDistributionBoxfilter(distributionBoxList);
      }
      if (selectedBox) {
        filteredAssets = filteredAssets.filter(
          (asset) => asset.distributionboxID === selectedBox
        );
      }
      if (selectedLocation && selectedLocation.trim()) {
        filteredAssets = filteredAssets.filter(
          (asset) => asset.assetLocation === selectedLocation
        );
      }
      console.log(filteredAssets);
      const sort = filteredAssets.sort(
        (a, b) => a.fittingNumber - b.fittingNumber
      );
      setFilteredData(sort);
      //setAssetsDemo(sort);
      setCollectionCard(cloneDeep(sort.slice(0, countPerPageCard)));
    } else {
      setAssetsDemo(data);
      setAssetsDemoCard(data);
      setCollectionCard(cloneDeep(data.slice(0, countPerPageCard)));
    }
  };
  const cancelForm = () => {
    if (state.state.category && state.state.site) {
      navigate(`${process.env.PUBLIC_URL}/sites/listsubcat`, {
        state: {
          category: state.state.category,
          site: state.state.site,
        },
      });
    } else {
      navigate(`${process.env.PUBLIC_URL}/catassetlist`);
    }
  };
  const handleChange = async (item, e) => {
    //console.log(e);
    try {
      var statusSp;
      if (item.status.toLowerCase() === "active") {
        statusSp = "Inactive";
        toast.success("Deactivated successfully.", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        statusSp = "Active";
        toast.success("Activated successfully.", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      //console.log(item);
      await client
        .graphql({
          query: mutations.updateAssets,
          variables: {
            input: { id: item.id, status: statusSp },
          },
        })
        .then((result) => {
          //console.log(result);
          getAssets();
          if (LoginUserGroup === "site_user") {
            fetchSitesDetailsSU();
          } else {
            fetchSitesDetails();
          }
        })
        .catch((error) => {
          //console.log(error);
          toast.error("Error while Updating ", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } catch (e) {
      toast.error("Error while Updating ", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  const ApproveStatus = (status) => {
    return status?.toLowerCase() === "active"
      ? "switch-state bg-success"
      : "switch-state bg-danger";
  };
  const navigatePage = (type) => {
    //console.log(type);
    type === "add"
      ? navigate(`${process.env.PUBLIC_URL}/asset/add`, {
        state: { category: state.state.category, site: state.state.site },
      })
      : navigate(`${process.env.PUBLIC_URL}/bulkimport`, {
        state: { category: state.state.category, site: state.state.site },
      });
  };
  const handleSiteDDChange = async (selectedOption) => {
    try {
      setOldSiteId(selectedSite);
      setSelectedSearchDBData("");
      setSelectedSite(selectedOption.value);
      const siteID = selectedOption.value;
      setSelectedSearchSiteData(selectedOption);
      console.log(fullAssets);
      const assetscount = assetsCountBySite.filter(
        (item) => item.categoriesID === state.state.category.id
      );
      console.log(assetscount);
      const result = assetscount.filter(
        (item) => item.sitedetailsID === siteID
      );
      console.log(`assetscount result:`, result);
      setAssetscountbysite(result);
    } catch (error) {
      console.error(error);
    }
  };
  const optionsDB = [
    { value: "", label: "All Distribution Board" },
    ...distributionBoxFilter.map((item) => ({
      value: item.id,
      label: item.distributionBoxName,
    })),
  ];
  const handleSiteDB = (selectedOption) => {
    assetRecordsSearchDBRef.current = [];
    isDBChange = true;
    setIsSearch(false);
    setSelectedBox(selectedOption.value);
    setSelectedSearchDBData(selectedOption);
  };
  const getSiteName = (siteId) => {
    try {
      const sites = siteDetails.filter((site) => {
        return site.id === siteId;
      });
      return sites.length > 0 ? sites[0].siteName : "";
    } catch (error) {
      console.log("Error while displaying site name", error);
      return "";
    }
  };
  const getDBName = (dbId) => {
    try {
      const db = distributionBoxList.filter((db) => {
        return db.id === dbId;
      });
      return db.length > 0 ? db[0].distributionBoxName : "";
    } catch (error) {
      console.log("display site name", error);
      return "";
    }
  };
  const handleCheckboxAssetChange = (event, itemId) => {
    let assets = isSearch ? filteredData : fullAssets;
    const isChecked = event.target.checked;
    if (itemId === "selectAll") {
      setIsAllSelected(isChecked);
      if (isChecked) {
        const allItemIds = fullItems.map((item) => item.id);
        setSelectedItemsAsset(allItemIds);
      } else {
        setSelectedItemsAsset([]);
      }
    } else {
      setSelectedItemsAsset((prevSelectedItems) => {
        if (!Array.isArray(prevSelectedItems)) {
          prevSelectedItems = [];
        }
        let updatedSelectedItems;
        if (isChecked && !prevSelectedItems.includes(itemId)) {
          updatedSelectedItems = [...prevSelectedItems, itemId];
        } else if (!isChecked && prevSelectedItems.includes(itemId)) {
          updatedSelectedItems = prevSelectedItems.filter(
            (item) => item !== itemId
          );
        } else {
          updatedSelectedItems = prevSelectedItems;
        }
        const allItemIds = assets.map((item) => item.id);
        const isAllChecked = updatedSelectedItems.length === allItemIds.length;
        setIsAllSelected(isAllChecked);
        return updatedSelectedItems;
      });
    }
  };
  const handleDeleteAssets = () => {
    try {
      let counter = 0;
      console.log("Selected Items Asset:", selectedItemsAsset);

      selectedItemsAsset.map(async (item, i) => {
        console.log("Item:", item);
        await sleep(Math.random() * i * 17); // Wait
        await client
          .graphql({
            query: mutations.updateAssets,
            variables: {
              input: {
                id: item,
                status: "delete",
              },
            },
            authMode: "API_KEY",
          })
          .then((result) => {
            console.log("Result:", result);
            counter = counter + 1;
            if (counter === selectedItemsAsset.length) {
              toast.success("Selected Assets Deleted Successfully.", {
                position: toast.POSITION.TOP_CENTER,
              });
              navigate(`${process.env.PUBLIC_URL}/asset/deleteList`, {
                state: {
                  category: state.state.category,
                  site: state.state.site,
                },
              });
            }
          })
          .catch((error) => {
            console.error("GraphQL Error:", error);
          });
      });
    } catch (error) {
      console.error("Error in handleDeleteAssets:", error);
    }
  };
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  //----subcription limit changes
  useEffect(() => {
    if (LoginUserGroup === "site_user") {
      fetchSubscriptionListSU();
      fetchSubscriptionListSUAssetsCount();
    } else {
      fetchSubscriptionList();
    }
  }, []);
  async function fetchSubscriptionListSUAssetsCount() {
    try {
      let nextToken = "";
      let sites = [];
      const result = await client.graphql({
        query: queries.siteDetailsByAssetownerID,
        variables: {
          assetownerID: LoginUserDetails.assetOwnerId,
          limit: 200000,
          filter: {
            status: {
              eq: "Active",
            },
          },
        },
        authMode: "API_KEY",
      });
      console.log(result);
      if (result.data.siteDetailsByAssetownerID.items.length > 0) {
        let assetDatas = result.data.siteDetailsByAssetownerID.items;
        nextToken = result.data.siteDetailsByAssetownerID.nextToken;
        if (result.data.siteDetailsByAssetownerID.items.length > 0) {
          sites.push(assetDatas);
        }
        while (nextToken !== null) {
          const results = await client.graphql({
            query: queries.siteDetailsByAssetownerID,
            variables: {
              assetownerID: LoginUserDetails.assetOwnerId,
              limit: 200000,
              filter: {
                status: {
                  eq: "Active",
                },
              },
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          console.log(results);
          if (results.data.siteDetailsByAssetownerID.items.length > 0) {
            nextToken = results.data.siteDetailsByAssetownerID.nextToken;
            if (results.data.siteDetailsByAssetownerID.items.length > 0) {
              sites.push(results.data.siteDetailsByAssetownerID.items);
            }
          }
        }
        console.log(sites);
        if (sites.length > 0) {
          const mergeSites = sites.flat(1);
          //fetchSpuser(mergeSites);
          SiteData.setSiteData(mergeSites);
          const promises1 = mergeSites.map(async (item) => {
            return client.graphql({
              query: queries.assetCountsBySitedetailsID,
              variables: {
                sitedetailsID: item.id,
                limit: 10000,
              },
            });
          });
          Promise.all(promises1).then(async (results) => {
            console.log(results);
            let assetCount = [];
            if (results.length > 0) {
              results.forEach((result1) => {
                let counts = result1.data.assetCountsBySitedetailsID;
                if (counts !== null) {
                  if (counts.items.length > 0) {
                    counts.items.map((item) => {
                      assetCount.push(item);
                    });
                  }
                }
              });
              console.log(assetCount);
              if (
                state.state &&
                state.state.category &&
                state.state.category.id
              ) {
                const catsitecount = assetCount.filter(
                  (item) => item.categoriesID === state.state.category.id
                );
                console.log(state.state.category.id);
                console.log(catsitecount);
                const totalAssetCount = assetCount.reduce(
                  (accumulator, current) => {
                    return accumulator + current.assetCount;
                  },
                  0
                );
                console.log(totalAssetCount);
                console.log(localStorage.getItem("categoryAssetCount"));
                if (localStorage.getItem("categoryAssetCount") !== null) {
                  let activeAssets = JSON.parse(
                    base64.decode(localStorage.getItem("categoryAssetCount"))
                  );
                  console.log(activeAssets);

                  if (assetsCountBySite.length > 0) {
                    const totalAssetCountsu = assetsCountBySite.reduce(
                      (acc, item) => acc + item.assetCount,
                      0
                    );
                    console.log(totalAssetCountsu);
                    console.log(totalAssetCount);
                    setAssetsCount(totalAssetCount + totalAssetCountsu);
                  }
                } else {
                  setAssetsCount(totalAssetCount);
                }
              }
            }
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function fetchSubscriptionListSU() {
    try {
      const response3 = await client.graphql({
        query: queries.listUserDetails,
        authMode: "API_KEY",
        variables: {
          limit: limit,
          filter: {
            id: {
              eq: LoginUserDetails.assetOwnerId,
            },
          },
        },
      });
      const assetowner = response3.data.listUserDetails.items;
      console.log(assetowner);
      if (assetowner.length > 0) {
        const response = await client.graphql({
          query: queries.listSubscriptionLimits,
          authMode: "API_KEY",
          variables: {
            limit: limit,
          },
        });
        if (response.data.listSubscriptionLimits.items.length > 0) {
          let setdata = [];
          setdata = response.data.listSubscriptionLimits.items;
          let assetLimitdata = setdata.filter(
            (item) => item.subscriptionId === assetowner[0].paymentId
          );
          console.log(assetLimitdata);
          let userdata = setdata.filter(
            (item) => item.SubscriptionName === "Asset Add-On"
          );
          console.log(userdata);
          setsubscriptionplan(assetLimitdata);
          if (assetLimitdata[0].subscriptionId !== "5") {
            try {
              const response1 = await client.graphql({
                query: queries.listSubscriptionAdds,
                authMode: "API_KEY",
                variables: {
                  limit: limit,
                },
              });
              if (response1.data.listSubscriptionAdds.items.length > 0) {
                const addons = response1.data.listSubscriptionAdds.items;
                let addonsdata = addons.filter(
                  (item) => item.userId === LoginUserDetails.id
                );
                console.log(addonsdata);
                const AssetAddOn = addonsdata.filter(
                  (item) => item.subscriptionId === "6"
                );
                console.log(AssetAddOn);
                if (AssetAddOn.length > 0) {
                  const sort = AssetAddOn.sort((a, b) => {
                    return new Date(b.createdAt) - new Date(a.createdAt);
                  });
                  const activeuser = AssetAddOn.filter(
                    (item) => item.status === "Active"
                  );
                  console.log(activeuser);
                  if (activeuser.length > 0) {
                    setAddOn(sort);
                    const totalQuantity = activeuser.reduce(
                      (acc, user) => acc + user.quantity,
                      0
                    );
                    console.log(totalQuantity);
                    const assetcount =
                      assetLimitdata[0].AssetLimit + totalQuantity;
                    setAssetplancount(assetcount);
                  } else {
                    const assetcount = assetLimitdata[0].AssetLimit;
                    console.log(assetcount);
                    setAssetplancount(assetcount);
                  }
                  // setAddOn(sort);
                  // const assetcount =
                  //   assetLimitdata[0].AssetLimit + sort.length * 5000;
                  // setAssetplancount(assetcount);
                } else {
                  const assetcount = assetLimitdata[0].AssetLimit;
                  console.log(assetcount);
                  setAssetplancount(assetcount);
                }
              } else {
                const assetcount = assetLimitdata[0].AssetLimit;
                console.log(assetcount);
                setAssetplancount(assetcount);
              }
            } catch (error) {
              console.error("GraphQL query error:", error);
              setAssetplancount(assetLimitdata[0].AssetLimit);
            }
          } else {
            setAssetplancount(assetLimitdata[0].AssetLimit);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchSubscriptionList() {
    try {
      const response = await client.graphql({
        query: queries.listSubscriptionLimits,
        authMode: "API_KEY",
        variables: {
          limit: limit,
        },
      });
      if (response.data.listSubscriptionLimits.items.length > 0) {
        let setdata = [];
        setdata = response.data.listSubscriptionLimits.items;
        let assetLimitdata = setdata.filter(
          (item) => item.subscriptionId === LoginUserDetails.paymentId
        );
        console.log(assetLimitdata);
        let userdata = setdata.filter(
          (item) => item.SubscriptionName === "Asset Add-On"
        );
        console.log(userdata);
        setsubscriptionplan(assetLimitdata);
        if (assetLimitdata[0].subscriptionId !== 5) {
          try {
            const response1 = await client.graphql({
              query: queries.listSubscriptionAdds,
              authMode: "API_KEY",
              variables: {
                limit: limit,
                filter: {
                  status: {
                    eq: "Active",
                  },
                },
              },
            });
            if (response1.data.listSubscriptionAdds.items.length > 0) {
              const addons = response1.data.listSubscriptionAdds.items;
              let addonsdata = addons.filter(
                (item) => item.userId === LoginUserDetails.id
              );
              console.log(addonsdata);
              const AssetAddOn = addonsdata.filter(
                (item) => item.subscriptionId === "6"
              );
              console.log(AssetAddOn);
              if (AssetAddOn.length > 0) {
                const sort = AssetAddOn.sort((a, b) => {
                  return new Date(b.createdAt) - new Date(a.createdAt);
                });
                const activeuser = AssetAddOn.filter(
                  (item) => item.status === "Active"
                );
                console.log(activeuser);
                if (activeuser.length > 0) {
                  setAddOn(sort);
                  const totalQuantity = activeuser.reduce(
                    (acc, user) => acc + user.quantity,
                    0
                  );
                  console.log(totalQuantity);
                  const assetcount =
                    assetLimitdata[0].AssetLimit + totalQuantity;
                  setAssetplancount(assetcount);
                } else {
                  const assetcount = assetLimitdata[0].AssetLimit;
                  console.log(assetcount);
                  setAssetplancount(assetcount);
                }
                // setAddOn(sort);
                // const assetcount =
                //   assetLimitdata[0].AssetLimit + sort.length * 5000;
                // setAssetplancount(assetcount);
              } else {
                const assetcount = assetLimitdata[0].AssetLimit;
                console.log(assetcount);
                setAssetplancount(assetcount);
              }
            } else {
              const assetcount = assetLimitdata[0].AssetLimit;
              console.log(assetcount);
              setAssetplancount(assetcount);
            }
          } catch (error) {
            console.error("GraphQL query error:", error);
            setAssetplancount(assetLimitdata[0].AssetLimit);
          }
        } else {
          setAssetplancount(assetLimitdata[0].AssetLimit);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleDeleteAllAssets = async () => {
    try {
      console.log("all assets deleting");
      setLoading(true);
      let nextToken = "";
      let assets = [];
      let count = 0;
      console.log(assetTemplatData);
      if (assetTemplatData.length > 0) {
        for (let assetTemplate of assetTemplatData) {
          count = count + 1;
          for (let sites of siteDetails) {
            let tempSiteId = assetTemplate.id + "_" + sites.id;
            const result = await client.graphql({
              query: queries.assetsByAssettemplateSiteIDAndAssetNum,
              variables: {
                assettemplateSiteID: tempSiteId,
                limit: limit,
              },
              authMode: "API_KEY",
            });
            if (
              result.data.assetsByAssettemplateSiteIDAndAssetNum.items.length >
              0
            ) {
              let assetDatas =
                result.data.assetsByAssettemplateSiteIDAndAssetNum.items;
              nextToken =
                result.data.assetsByAssettemplateSiteIDAndAssetNum.nextToken;
              if (
                result.data.assetsByAssettemplateSiteIDAndAssetNum.items
                  .length > 0
              ) {
                assets.push(assetDatas);
              }
              while (nextToken !== null) {
                const results = await client.graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: tempSiteId,
                    nextToken: nextToken,
                    limit: limit,
                  },
                  authMode: "API_KEY",
                });
                if (
                  results.data.assetsByAssettemplateSiteIDAndAssetNum.items
                    .length > 0
                ) {
                  nextToken =
                    results.data.assetsByAssettemplateSiteIDAndAssetNum
                      .nextToken;
                  if (
                    results.data.assetsByAssettemplateSiteIDAndAssetNum.items
                      .length > 0
                  ) {
                    assets.push(
                      results.data.assetsByAssettemplateSiteIDAndAssetNum.items
                    );
                  }
                }
              }
            }
          }
          console.log(assets);
          console.log(count);
          console.log(assetTemplatData);
          if (count === assetTemplatData.length) {
            const mergeAssets = assets.flat(1);
            console.log(mergeAssets);
            //--deleting process
            if (mergeAssets.length > 0) {
              let counter = 0;
              mergeAssets.map(async (item, i) => {
                await sleep(Math.random() * i * 17); // wait
                await client
                  .graphql({
                    query: mutations.updateAssets,
                    variables: {
                      input: { id: item.id, status: "delete" },
                    },
                    authMode: "API_KEY",
                  })
                  .then((result) => {
                    console.log(result);
                    counter = counter + 1;
                    if (counter === mergeAssets.length) {
                      setLoading(false);
                      toast.success("Selected Assets Deleted Successfully.", {
                        position: toast.POSITION.TOP_CENTER,
                      });
                      navigate(`${process.env.PUBLIC_URL}/asset/deleteList`, {
                        state: {
                          category: state.state.category,
                          site: state.state.site,
                        },
                      });
                    }
                  })
                  .catch((error) => {
                    console.error(error);
                    setLoading(false);
                  });
              });
            }
          }
          //   }
          // }
        }
      } else {
        const assetTemplateResults = await client.graphql({
          query: queries.assetTemplatesByCategoriesID,
          variables: {
            categoriesID: catid,
            filter: {
              status: {
                eq: "Active",
              },
            },
            limit: 800,
          },
          authMode: "API_KEY",
        });

        if (
          assetTemplateResults.data.assetTemplatesByCategoriesID.items.length >
          0
        ) {
          setAssetTemplatData(
            assetTemplateResults.data.assetTemplatesByCategoriesID.items
          );
          const assettempdata =
            assetTemplateResults.data.assetTemplatesByCategoriesID.items;
          for (let assetTemplate of assettempdata) {
            count = count + 1;
            //siteDetails.map(async (sites) => {
            for (let sites of siteDetails) {
              let tempSiteId = assetTemplate.id + "_" + sites.id;
              const result = await client
                .graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: tempSiteId,
                    limit: limit,
                  },
                  authMode: "API_KEY",
                })
                .then(async (result) => {
                  let assetDatas =
                    result.data.assetsByAssettemplateSiteIDAndAssetNum.items;
                  nextToken =
                    result.data.assetsByAssettemplateSiteIDAndAssetNum
                      .nextToken;
                  if (
                    result.data.assetsByAssettemplateSiteIDAndAssetNum.items
                      .length > 0
                  ) {
                    assets.push(assetDatas);
                  }
                  while (nextToken !== null) {
                    const results = await client.graphql({
                      query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                      variables: {
                        assettemplateSiteID: tempSiteId,
                        nextToken: nextToken,
                        limit: limit,
                      },
                      authMode: "API_KEY",
                    });
                    if (
                      results.data.assetsByAssettemplateSiteIDAndAssetNum.items
                        .length > 0
                    ) {
                      nextToken =
                        results.data.assetsByAssettemplateSiteIDAndAssetNum
                          .nextToken;
                      if (
                        results.data.assetsByAssettemplateSiteIDAndAssetNum
                          .items.length > 0
                      ) {
                        assets.push(
                          results.data.assetsByAssettemplateSiteIDAndAssetNum
                            .items
                        );
                      }
                    }
                  }
                });
            }
            console.log(assets);
            console.log(count);
            console.log(assettempdata);
            if (count === assettempdata.length) {
              const mergeAssets = assets.flat(1);
              console.log(mergeAssets);
              //--deleting process
              if (mergeAssets.length > 0) {
                let counter = 0;
                mergeAssets.map(async (item, i) => {
                  await sleep(Math.random() * i * 17); // wait
                  await client
                    .graphql({
                      query: mutations.updateAssets,
                      variables: {
                        input: { id: item.id, status: "delete" },
                      },
                      authMode: "API_KEY",
                    })
                    .then((result) => {
                      console.log(result);
                      counter = counter + 1;
                      if (counter === mergeAssets.length) {
                        setLoading(false);
                        toast.success("Selected Assets Deleted Successfully.", {
                          position: toast.POSITION.TOP_CENTER,
                        });
                        navigate(`${process.env.PUBLIC_URL}/asset/deleteList`, {
                          state: {
                            category: state.state.category,
                            site: state.state.site,
                          },
                        });
                      }
                    })
                    .catch((error) => {
                      console.error(error);
                      setLoading(false);
                    });
                });
              }
            }
            //});
            //}
          }
        }
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const renderList = (
    <Fragment>
      <Container fluid={true} className="p-0 project-list">
        {state.state && state.state.category && (
          <H1>{state.state.category.categoryName}</H1>
        )}
        <Card className="custom-card">
          <Row>
            <Col md="3">
              <label>Select Site</label>
              {(state.state && state.state.site && state.state.site.id) ||
                selectedSiteGlobal ? (
                <select
                  className="form-select"
                  disabled={
                    state.state.site ? true : selectedSiteGlobal ? true : false
                  }
                  value={
                    state.state.site ? state.state.site.id : selectedSiteGlobal
                  }
                  menuPlacement="auto"
                  menuPosition="fixed"
                >
                  {state.state && state.state.site && state.state.site.id && (
                    <option value={state.state.site.id}>
                      {state.state.site.siteName}
                    </option>
                  )}
                  {selectedSiteGlobal &&
                    siteDetails.map((site, index) => (
                      <option
                        value={site.id}
                        key={index}
                        selected={selectedSiteGlobal === site.id}
                      >
                        {site?.siteName}
                      </option>
                    ))}
                </select>
              ) : (
                <Select
                  value={selectedSearchSiteData}
                  onChange={handleSiteDDChange}
                  options={sites}
                  isSearchable={true}
                  hideSelectedOptions={false}
                  menuPlacement="auto"
                  menuPosition="fixed"
                />
              )}
            </Col>
            <Col md="3">
              <label>Select Distribution Board</label>
              <Select
                value={selectedSearchDBData}
                onChange={handleSiteDB}
                options={optionsDB}
                isSearchable={true}
                hideSelectedOptions={false}
                menuPlacement="auto"
                menuPosition="fixed"
              />
            </Col>
            <Col md="6" className="d-flex">
              <Btn
                attrBtn={{
                  color: "primary",
                  onClick: handleSearch,
                  disabled: isSearch,
                }}
              >
                Search
              </Btn>
              {!deleteAsset ? (
                <Btn
                  attrBtn={{ color: "primary", onClick: () => setDelete(true) }}
                >
                  Delete
                </Btn>
              ) : (
                <Btn attrBtn={{ color: "primary", onClick: deleteToggle }}>
                  Confirm Delete
                </Btn>
              )}
              <Btn attrBtn={{ color: "primary", onClick: deleteAllToggle }}>
                Delete All Assets
              </Btn>
              <Btn
                attrBtn={{
                  color: "primary",
                  onClick: () => {
                    isReset = true;
                    assetRecordsRef.current = [];
                    assetRecordsSearchRef.current = [];
                    assetRecordsSearchDBRef.current = [];
                    getAssets();
                    setItems([]);
                    setFullAssets([]);
                    setIsSearch(false);
                    setFullItems([]);
                    setSearchAssets(false);
                    setIsSecoundSearch(false);
                    setDelete(false);
                    setIsLoading(true);
                    state.state.site
                      ? setSelectedSite(state.state.site.id)
                      : setSelectedSite("");
                    setSelectedBox("");
                    setValue("");
                    setSelectedLocation("");
                    setSelectedSearchSiteData("");
                    setSelectedSearchDBData("");
                    setSelectedItemsAsset([]);
                  },
                  disabled: !isSearch,
                }}
              >
                {!deleteAsset ? "Reset" : "Reset Delete"}
              </Btn>
              <Link
                to={`${process.env.PUBLIC_URL}/asset/deleteList`}
                state={{
                  category:
                    state.state && state.state.category
                      ? state.state.category
                      : "",
                  site: state.state && state.state.site ? state.state.site : "",
                }}
                className="link"
              >
                Deleted Assets
              </Link>
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md="12" className="text-end">
              <div className="assetAdd">
                <Media body className="icon-stateCat switch-smcat">
                  <Label className="mb-0 switchCat">
                    <input
                      type="checkbox"
                      onClick={activeInactive}
                      value={isActive ? "Active" : "Inactive"}
                    />
                    <span
                      className={
                        isActive
                          ? "switch-state bg-success"
                          : "switch-state bg-danger"
                      }
                    ></span>
                  </Label>
                </Media>
                {state.state &&
                  state.state.category &&
                  state.state.category.status !== "Inactive" && (
                    <>
                      {(
                        addOn.length > 0
                          ? assetsCount >= assetPlancount &&
                          new Date(addOn[0].dueDate) > new Date()
                          : assetsCount >= assetPlancount
                      ) ? (
                        <>
                          <Dropdown className="separated-btn">
                            <Dropdown.Toggle
                              variant="primary"
                              id="dropdown-basic"
                              title="Assets Limit Exceeded"
                            >
                              Add Assets
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {userPermission.includes("Create Assets") && (
                                <Dropdown.Item title="Assets Limit Exceeded">
                                  Add Asset
                                </Dropdown.Item>
                              )}
                              {userPermission.includes("Import Assets") && (
                                <Dropdown.Item title="Assets Limit Exceeded">
                                  Bulk Import
                                </Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </>
                      ) : (
                        <>
                          <Dropdown className="separated-btn">
                            <Dropdown.Toggle
                              variant="primary"
                              id="dropdown-basic"
                            >
                              Add Assets
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {userPermission.includes("Create Assets") && (
                                <Dropdown.Item
                                  onClick={() => navigatePage("add")}
                                >
                                  Add Asset
                                </Dropdown.Item>
                              )}
                              {userPermission.includes("Import Assets") && (
                                <Dropdown.Item
                                  onClick={() => navigatePage("import")}
                                >
                                  Bulk Import
                                </Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </>
                      )}
                    </>
                  )}
                <div className="text-end">
                  <Btn attrBtn={{ color: "primary", onClick: cancelForm }}>
                    Back
                  </Btn>
                </div>
              </div>
            </Col>
          </Row>
          {(addOn.length > 0
            ? assetsCount >= assetPlancount &&
            new Date(addOn[0].dueDate) > new Date()
            : assetsCount >= assetPlancount) && (
              <Row>
                <Col md="6">
                  {(LoginUserGroup === "site_owner" ||
                    LoginUserGroup === "spao") && (
                      <p className="errorSubscription">
                        Sorry!.. Your Asset Limit is Exceeded, Please Upgrade your
                        Subscription to{" "}
                        {LoginUserDetails.paymentId === "1"
                          ? "Tier 1"
                          : LoginUserDetails.paymentId === "2"
                            ? "Tier 2"
                            : "Corporate"}
                      </p>
                    )}
                  {LoginUserGroup === "site_user" && (
                    <p className="errorSubscription">
                      Sorry!.. Your Asset Limit is Exceeded.
                      {/* Please Upgrade your
                    Subscription to{" "}
                    {LoginUserDetails.paymentId === "1"
                      ? "Tier 1"
                      : LoginUserDetails.paymentId === "2"
                        ? "Tier 2"
                        : "Corporate"} */}
                    </p>
                  )}
                </Col>
                {LoginUserGroup !== "site_user" && (
                  <Col md="6">
                    <Link to={`${process.env.PUBLIC_URL}/subscriptions`}>
                      <H4>Click here to Top Up the Assets</H4>
                    </Link>
                  </Col>
                )}
              </Row>
            )}
        </Card>
        <Row></Row>
        {assetView === "card" ? (
          <div className="card-block row">
            <Pagination
              className="p-t-10"
              pageSize={countPerPageCard}
              onChange={updatePageCard}
              current={currentPageCard}
              total={
                value ||
                  (state.state && state.state.site ? false : selectedSite) ||
                  selectedLocation ||
                  selectedBox
                  ? filteredData.length
                  : state.state && state.state.site && state.state.category
                    ? assetDemoCard.length
                    : assetDemoCard.length
              }
              showTotal={(total, range) =>
                `${range[0]} - ${range[1]} of ${total} items`
              }
            />
            <Row>
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <>
                  {collectionCard.filter((item) =>
                    isActive
                      ? item.status === "Active"
                      : item.status === "Inactive"
                  ).length > 0 ? (
                    collectionCard &&
                    collectionCard
                      .filter((item) =>
                        isActive
                          ? item.status === "Active"
                          : item.status === "Inactive"
                      )
                      .map((item) => (
                        <Col md="3" key={item.id}>
                          <Link
                            to={`${process.env.PUBLIC_URL}/asset/view`}
                            state={{
                              category: state.state.category,
                              item: item,
                            }}
                          >
                            <Card>
                              <p className="activestatus">{item.assetModel}</p>
                              <div
                                className={
                                  item.status === "Active"
                                    ? "ribbon ribbon-clip-right ribbon-right ribbon-success"
                                    : "ribbon ribbon-clip-right ribbon-right ribbon-danger"
                                }
                              >
                                {item.status}
                              </div>
                              <CardBody>
                                <Media className="static-widget">
                                  <br></br>
                                  <Media body>
                                    <H4
                                      id="status"
                                      attrH6={{ className: "font-roboto" }}
                                    >
                                      {" "}
                                      {getDBName(item.distributionboxID)}{" "}
                                    </H4>
                                    <br></br>
                                    <b>Site</b>
                                    <br></br>
                                    {getSiteName(item.sitedetailsID)}
                                    <br></br> <br></br>
                                    <b>Installed On</b>
                                    <br></br>
                                    {moment(item.installedOn).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </Media>
                                </Media>
                              </CardBody>
                            </Card>
                          </Link>
                        </Col>
                      ))
                  ) : (
                    <p>No Assets Found</p>
                  )}{" "}
                </>
              )}
            </Row>
          </div>
        ) : (
          <Card className="custom-card">
            <div className="card-block row">
              <Col sm="12" lg="12" xl="12">
                Loaded{" "}
                {
                  fullItems.filter((item) =>
                    isActive
                      ? item.status === "Active"
                      : item.status === "Inactive"
                  ).length
                }{" "}
                of {state.state.category.assetsCount} Assets
                <InfiniteScroll
                  dataLength={fullItems.length}
                  next={isSearch ? getAssetsLoopSearch : getAssetsLoop}
                  hasMore={hasMore}
                  height={500}
                  loader={
                    hasMore ? (
                      <p style={{ textAlign: "center" }}>
                        <b>Loading More Assets...</b>
                      </p>
                    ) : (
                      <p style={{ textAlign: "center" }}>
                        <b>No Assets To Load.</b>
                      </p>
                    )
                  }
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      {fullItems.length > 0 ? (
                        <b>All Assets Loaded</b>
                      ) : (
                        <b>No Assets To Load.</b>
                      )}
                    </p>
                  }
                >
                  <Table className="table-striped  table-de ">
                    <thead
                      className="custom-table-head"
                      style={{
                        position: "sticky",
                        top: "0",
                        backgroundColor: "#fff",
                      }}
                    >
                      <tr>
                        {deleteAsset && (
                          <th>
                            <input
                              type="checkbox"
                              checked={isAllSelected}
                              onChange={(event) =>
                                handleCheckboxAssetChange(event, "selectAll")
                              }
                            />
                          </th>
                        )}
                        <th>Fitting No.</th>
                        <th>Asset Model</th>
                        <th>Site</th>
                        <th>Distribution board</th>
                        <th>Installed On</th>
                        <th>Asset Warranty</th>
                        <th>Asset Location</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody className="trhover">
                      {fullItems.filter((item) =>
                        isActive
                          ? item.status === "Active"
                          : item.status === "Inactive"
                      ).length > 0 &&
                        fullItems &&
                        fullItems
                          .filter((item) =>
                            isActive
                              ? item.status === "Active"
                              : item.status === "Inactive"
                          )
                          .sort((a, b) => a.fittingNumber - b.fittingNumber)
                          .map((item, i) => (
                            <tr
                              key={item.id}
                              className={item.duplicate ? "duplicateRow" : ""}
                              title={item.duplicate ? item.duplicate : ""}
                            >
                              {deleteAsset && (
                                <td>
                                  <input
                                    type="checkbox"
                                    name="completionLink"
                                    checked={selectedItemsAsset.includes(
                                      item.id
                                    )}
                                    onChange={(event) =>
                                      handleCheckboxAssetChange(event, item.id)
                                    }
                                  />
                                </td>
                              )}
                              <td>
                                {userPermission.includes("Edit Assets") ? (
                                  state.state && (
                                    <Link
                                      to={`${process.env.PUBLIC_URL}/asset/view`}
                                      state={{
                                        category: state.state.category,
                                        item: item,
                                        site: getSiteName(item.sitedetailsID),
                                        db: getDBName(item.distributionboxID),
                                      }}
                                    >
                                      {item.fittingNumber}
                                    </Link>
                                  )
                                ) : (
                                  <>{item.fittingNumber}</>
                                )}
                              </td>
                              <td>{item.assetModel}</td>
                              <td>{getSiteName(item.sitedetailsID)}</td>
                              <td>{getDBName(item.distributionboxID)}</td>
                              <td>
                                {item.installedOn !== null
                                  ? moment(item.installedOn).format(
                                    "DD/MM/YYYY"
                                  )
                                  : "-"}
                              </td>
                              {item.assetWarrantyStart != null && (
                                <td>
                                  {moment(item.assetWarrantyStart).format(
                                    "DD/MM/YYYY"
                                  )}{" "}
                                  -
                                  {moment(item.assetWarrantyEnd).format(
                                    "DD/MM/YYYY"
                                  )}
                                </td>
                              )}
                              {item.assetWarrantyStart === null && <td>-</td>}
                              <td>{item.assetLocation}</td>
                              <td>
                                <Media body className="icon-state switch-sm">
                                  <Label className="switch">
                                    <input
                                      type="checkbox"
                                      onChange={(e) => handleChange(item, e)}
                                    />
                                    <span
                                      className={ApproveStatus(item.status)}
                                    ></span>
                                  </Label>
                                </Media>
                              </td>
                            </tr>
                          ))}{" "}
                    </tbody>
                  </Table>
                </InfiniteScroll>
              </Col>
            </div>
          </Card>
        )}
      </Container>
      <Modal isOpen={deleteModal} toggle={deleteToggle}>
        <ModalBody>
          <Form className="form-bookmark">
            <H4>Are you sure, to delete selected Assets??</H4>
            <Btn
              attrBtn={{
                color: "primary",
                className: "ms-1",
                onClick: deleteToggle,
              }}
            >
              Cancel
            </Btn>
            &nbsp;&nbsp;
            <Btn attrBtn={{ color: "primary", onClick: handleDeleteAssets }}>
              {Loading ? "Deleting..." : "Ok"}
            </Btn>
          </Form>
        </ModalBody>
      </Modal>
      <Modal isOpen={deleteAllModal} toggle={deleteAllToggle}>
        <ModalBody>
          <Form className="form-bookmark">
            <H4>
              Are you sure, to delete{" "}
              {selectedSite
                ? assetscountbysite[0] && assetscountbysite[0].assetCount
                : state.state.category.assetsCount}{" "}
              Assets??
            </H4>
            <Btn
              attrBtn={{
                color: "primary",
                className: "ms-1",
                onClick: deleteAllToggle,
              }}
            >
              Cancel
            </Btn>
            &nbsp;&nbsp;
            <Btn attrBtn={{ color: "primary", onClick: handleDeleteAllAssets }}>
              {Loading ? "Deleting..." : "Ok"}
            </Btn>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
  return (
    <div>
      {isLoading ? <LoadingSpinner /> : renderList}
      {errorMessage && <div className="error">{errorMessage}</div>}
    </div>
  );
};
export default AssetsListDetails;
