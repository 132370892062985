import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Row,
  Table,
  Col,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
} from "reactstrap";
import "rc-pagination/assets/index.css";

import * as queries from "../../graphql/queries";
import { toast } from "react-toastify";
import moment from "moment";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { Btn, H2, H3, H6, H5, LI, P, UL } from "../../AbstractElements";
import { Subscribe } from "../../Constant";
import * as mutations from "../../graphql/mutations";
import { USERADDON, ASSETADDON, LITE, TIER1, TIER2, CORPORATE } from "../../Config/SubscriptionPaymentLinks";
import { useForm } from "react-hook-form";
import { loadStripe } from "@stripe/stripe-js";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});

var base64 = require("base-64");
const stripePromise = loadStripe(
  "pk_live_51NM5Y7IeIZdDV3cdT1Li68nTCWYwP2da8HqTiNlnVojV0POHEKq3SbcOG4mKEhm4NMHOfA70MA2useZiQsnrUdTm0094DEVNb0"
);

const stripe = require("stripe")(
  "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"
);

const SubscriptionsDetails = () => {
  const [subscriptionlist, setSubscriptionlist] = useState([]);
  const [assetsCountOftheAO, setAssetsCountOftheAO] = useState(0);
  const [siteCountOftheAO, setsiteCountOftheAO] = useState(0);
  const [siteUserCountOftheAO, setsiteUserCountOftheAO] = useState(0);
  const [spUserCountOftheAO, setspUserCountOftheAO] = useState(0);
  const [siteDetails, setSiteDetails] = useState([]);
  const [assetLimit, setassetLimit] = useState(0);
  const [siteLimit, setsiteLimit] = useState(0);
  const [siteuserLimit, setsiteuserLimit] = useState(0);
  const [spuserLimit, setspuserLimit] = useState(0);
  const [otherSubscriptions, setOtherSubscriptions] = useState([]);
  const [subscriptionName, setsubscriptionName] = useState("");
  var LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const LoginUserGroup = localStorage.getItem("groups");
  const [subscriptionsplansData, setSubscriptionsplansData] = useState([]);
  const [userAddOn, setUserAddOn] = useState([]);
  const [assetAddOn, setAssetAddOn] = useState([]);
  const [subscriptionType, setSubscriptionType] = useState([]);
  const [price, setPrice] = useState("");
  const [siteDetailss, setSiteDetailss] = useState([]);
  const [viewPayment, setViewPayment] = useState(false);
  const history = useNavigate();
  const menueRedirect = {
    site_owner: "dashboards",
    electrician: "dashboard/default",
    site_user: "dashboard/default",
    admin: "dashboards",
  };
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    e,
    ...form
  } = useForm();

  //----------------------------------------------------------------------------
  async function onSubmit(data) {
    try {
      if (data.id === "5") {
        window.open("https://knowyourasset.com.au/contact/", "_blank");
      } else {
        const bilId = data.id;
        const updateUser = await client.graphql({
          query: mutations.updateUserDetails,
          variables: {
            input: { id: LoginUserDetails.id, paymentId: bilId },
          },
        });
        console.log(updateUser);
        toast.success(
          "Plan " + data.title + " has been choosen Succesfully!..",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        await client
          .graphql({
            query: queries.getUserDetails,
            variables: {
              id: LoginUserDetails.id,
            },
          })
          .then((userDetail) => {
            if (bilId === "1") {
              window.location.href = LITE + LoginUserDetails.id;
            }
            else if (bilId === "2") {
              window.location.href = TIER1 + LoginUserDetails.id;
            }
            else if (bilId === "3") {
              window.location.href = TIER2 + LoginUserDetails.id;
            }
            // refreshPage();
            // localStorage.setItem(
            //   "userDetails",
            //   JSON.stringify(userDetail.data.getUserDetails)
            // );
            // history(
            //   `${process.env.PUBLIC_URL}/${menueRedirect[
            //   userDetail.signInUserSession.accessToken.payload[
            //   "cognito:groups"
            //   ][0]
            //   ]
            //   }`
            // );
            // LoginUserDetails = userDetail.data.getUserDetails;


            // fetchSubscriptionList();
            if (LoginUserGroup === "site_user") {
              fetchSubscriptionListSU();
            }
            else {
              fetchSubscriptionList();
            }
            fetchSiteDetails();
            fetchSiteUsersCountSPUserDetails();
            fetchSiteUsersCountSiteUserDetails();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err) {
      toast.error(err, {
        position: toast.POSITION.TOP_CENTER,
      });
      console.error(err);
    }
  }

  async function fetchSitesDetails() {
    try {
      await client
        .graphql({
          query: queries.siteDetailsByAssetownerID,
          variables: {
            assetownerID: LoginUserDetails.id,
            limit: 10000,
          },
          authMode: "API_KEY",
        })
        .then(async (result) => {
          const sites = result.data.siteDetailsByAssetownerID.items;
          let nextToken = result.data.siteDetailsByAssetownerID.nextToken;
          let siteData = [];
          if (sites.length > 0) {
            siteData.push(sites);
          }
          while (nextToken !== null) {
            await client
              .graphql({
                query: queries.siteDetailsByAssetownerID,
                variables: {
                  assetownerID: LoginUserDetails.id,
                  limit: 10000,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              })
              .then((result) => {
                nextToken = result.data.siteDetailsByAssetownerID.nextToken;
                if (result.data.siteDetailsByAssetownerID.items.length > 0) {
                  siteData.push(result.data.siteDetailsByAssetownerID.items);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (siteData.length > 0) {
            let mergeSites = siteData.flat(1);
            setsiteCountOftheAO(mergeSites.length);
            const activesites = mergeSites.filter(
              (item) => item.status === "Active"
            );
            setSiteDetailss(activesites);
            localStorage.setItem(
              "site",
              base64.encode(JSON.stringify(activesites))
            );
          } else {
            setSiteDetailss([]);

          }
        });
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (localStorage.getItem("site") === null && siteDetailss.length === 0) {
      fetchSitesDetails();
    } else {
      let sites = JSON.parse(base64.decode(localStorage.getItem("site")));
      let activeSites = sites.filter((item) => item.status === "Active");
      setSiteDetailss(activeSites);
      setsiteCountOftheAO(sites.length);
    }
  }, [])


  const viewPaymentModaltoggle = () => setViewPayment(!viewPayment);

  //------------------------------------------------------------
  function refreshPage() {
    window.location.reload(true);
  }
  //-------------------------------------------------------------
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      toast.success(
        "User Topup was successfull!. You can continue to add on the users.",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      console.log("Order placed! You will receive an email confirmation.");
    }

    if (query.get("canceled")) {
      toast.error("Payment canceled. Please try again.", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.log(
        "Order canceled -- continue to shop around and checkout when you’re ready."
      );
    }
    fetchSiteDetails();
    fetchSiteUsersCountSPUserDetails();
    fetchSiteUsersCountSiteUserDetails();
    if (LoginUserGroup === "site_user") {
      fetchSubscriptionListSU();
    }
    else {
      fetchSubscriptionList();
    }
  }, []);

  async function fetchSiteDetails() {
    try {
      console.log(siteDetailss);
      if (siteDetailss.length > 0) {
        const promises1 = siteDetailss.map(async (item) => {
          return client.graphql({
            query: queries.assetCountsBySitedetailsID,
            variables: {
              sitedetailsID: item.id,
              limit: 10000,
            },
          });
        });
        Promise.all(promises1).then(async (results) => {
          console.log(results);
          let assetCount = [];
          if (results.length > 0) {
            results.forEach((result1) => {
              let counts = result1.data.assetCountsBySitedetailsID;
              if (counts !== null) {
                if (counts.items.length > 0) {
                  counts.items.map((item) => {
                    assetCount.push(item);
                  });
                }
              }
            });
            console.log(assetCount);
            const totalAssetCount = assetCount.reduce(
              (accumulator, current) => {
                return accumulator + current.assetCount;
              },
              0
            );
            console.log(totalAssetCount);
            setAssetsCountOftheAO(totalAssetCount);
          }
        });
      }
      else {
        await client
          .graphql({
            query: queries.siteDetailsByAssetownerID,
            variables: {
              assetownerID: LoginUserDetails.id,
              limit: 10000,
            },
            authMode: "API_KEY",
          })
          .then(async (result) => {
            const sites = result.data.siteDetailsByAssetownerID.items;
            let nextToken = result.data.siteDetailsByAssetownerID.nextToken;
            let siteData = [];
            if (sites.length > 0) {
              siteData.push(sites);
            }
            while (nextToken !== null) {
              await client
                .graphql({
                  query: queries.siteDetailsByAssetownerID,
                  variables: {
                    assetownerID: LoginUserDetails.id,
                    limit: 10000,
                    nextToken: nextToken,
                  },
                  authMode: "API_KEY",
                })
                .then((result) => {
                  nextToken = result.data.siteDetailsByAssetownerID.nextToken;
                  if (result.data.siteDetailsByAssetownerID.items.length > 0) {
                    siteData.push(result.data.siteDetailsByAssetownerID.items);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            if (siteData.length > 0) {
              let mergeSites = siteData.flat(1);
              const activesites = mergeSites.filter(
                (item) => item.status === "Active"
              );
              //---
              const promises1 = mergeSites.map(async (item) => {
                return client.graphql({
                  query: queries.assetCountsBySitedetailsID,
                  variables: {
                    sitedetailsID: item.id,
                    limit: 10000,
                  },
                });
              });
              Promise.all(promises1).then(async (results) => {
                console.log(results);
                let assetCount = [];
                if (results.length > 0) {
                  results.forEach((result1) => {
                    let counts = result1.data.assetCountsBySitedetailsID;
                    if (counts !== null) {
                      if (counts.items.length > 0) {
                        counts.items.map((item) => {
                          assetCount.push(item);
                        });
                      }
                    }
                  });
                  console.log(assetCount);
                  const totalAssetCount = assetCount.reduce(
                    (accumulator, current) => {
                      return accumulator + current.assetCount;
                    },
                    0
                  );
                  console.log(totalAssetCount);
                  setAssetsCountOftheAO(totalAssetCount);
                }
              });
            } else {
              setSiteDetailss([]);

            }
          });
      }
      // await client
      //   .graphql({
      //     query: queries.siteDetailsByAssetownerID,
      //     variables: {
      //       assetownerID: LoginUserDetails.id,
      //       limit: 10000,
      //     },
      //     authMode: "API_KEY",
      //   })
      //   .then(async (result) => {
      //     const sites = result.data.siteDetailsByAssetownerID.items;
      //     if (sites.length > 0) {
      //       setSiteDetails(result.data.siteDetailsByAssetownerID.items);
      //       setsiteCountOftheAO(
      //         result.data.siteDetailsByAssetownerID.items.length
      //       );
      //       await client
      //         .graphql({
      //           query: queries.listAssets,
      //           variables: {
      //             limit: 200000,
      //           },
      //         })
      //         .then((result1) => {
      //           console.log(result1);
      //           const assetList = result1.data.listAssets.items.filter((item) => {
      //             const _matchSites = sites?.find(
      //               (user) => user.id === item?.siteId
      //             );
      //             if (_matchSites) {
      //               return item;
      //             }
      //           });
      //           setAssetsCountOftheAO(assetList.length);
      //         })
      //         .catch((error) => {
      //           console.log(error);
      //         });
      //     } else {
      //       setAssetsCountOftheAO(0);
      //     }
      //   });
    }
    catch (error) {
      console.error(error);
    }
  }

  async function fetchSiteUsersCountSiteUserDetails() {
    try {
      await client
        .graphql({
          query: queries.listSPUserDetails,
          variables: {
            filter: {
              invitedBy: {
                eq: LoginUserDetails.id,
              },
            },
            limit: 10000,
          },
          authMode: "API_KEY",
        })
        .then((result) => {
          let dataDetails = result.data.listSPUserDetails.items.filter(
            (item) => item.userGroup === "site_user"
          );
          setsiteUserCountOftheAO(dataDetails.length);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    catch (error) {
      console.error(error);
    }
  }

  async function fetchSiteUsersCountSPUserDetails() {
    try {
      await client
        .graphql({
          query: queries.listSPUserDetails,
          variables: {
            filter: {
              invitedBy: {
                eq: LoginUserDetails.id,
              },
            },
            limit: 10000,
          },
          authMode: "API_KEY",
        })
        .then((result) => {
          let dataDetails = result.data.listSPUserDetails.items.filter(
            (item) => item.userGroup === "electrician"
          );
          setspUserCountOftheAO(dataDetails.length);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    catch (error) {
      console.error(error);
    }
  }

  async function fetchSubscriptionList() {
    try {
      const response = await client.graphql({
        query: queries.listSubscriptionLimits,
        authMode: "API_KEY",
        variables: {
          limit: 10000,
        },
      });
      if (response.data.listSubscriptionLimits.items.length > 0) {
        let plansData = [];
        let setdata = response.data.listSubscriptionLimits.items;
        console.log("sub setdata", setdata);
        let assetLimitdata = setdata.filter(
          (item) => item.subscriptionId === LoginUserDetails.paymentId
        );
        console.log("setdata", assetLimitdata);
        console.log(Number(LoginUserDetails.paymentId) + 1);
        setOtherSubscriptions(
          setdata.filter(
            (item) => item.subscriptionId !== LoginUserDetails.paymentId
          )
        );
        setUserAddOn(
          setdata.filter(
            (item) => item.Type === "addOn" && item.subscriptionId === "7"
          )
        );
        setAssetAddOn(
          setdata.filter(
            (item) => item.Type === "addOn" && item.subscriptionId === "6"
          )
        );
        setSubscriptionlist(setdata);
        setassetLimit(assetLimitdata[0].AssetLimit);
        setsiteLimit(assetLimitdata[0].SiteLimit);
        setsiteuserLimit(assetLimitdata[0].SiteUserLimit);
        setspuserLimit(assetLimitdata[0].SPUserLimit);
        setsubscriptionName(assetLimitdata[0].SubscriptionName);
        // setsubscriptiondata(response.data.listSubscriptionLimits.items);
        // "If your company or organisation has a number of assets well in excess of our current packages, contact us at team@knowyourasset.com.au to discuss a tailored package to suit"

        for (let item of setdata) {
          if (item.Type === "plan") {
            let storage1Text =
              item.subscriptionId === "5"
                ? "If your company or organisation has a number of assets well in excess of our current packages, click below to discuss a tailored package to suit."
                : (item.MinAsset === null ? 0 : item.MinAsset) +
                "-" +
                item.AssetLimit;
            let props1Text = item.subscriptionId === "5" ? "" : "Assets";
            plansData.push({
              id: item.subscriptionId,
              prize: "$" + item.Price,
              title: item.SubscriptionName,
              month: "/month",
              storage1: storage1Text,
              props1: props1Text,
              // storage2: item.SiteLimit,
              storage2: "Unlimited",
              props2: "Sites",
              storage3: item.SiteUserLimit,
              // props3: 'SiteUserLimit',
              props3: "Users",
              // storage4: item.SPUserLimit,
              // props4: 'SP User Limit'
              // storage5: '15',
              // props5: 'Domains',
            });
          }
        }
        setSubscriptionsplansData(plansData);
        console.log("data of the subscription list--");
        console.log(setdata);
      }
    }
    catch (error) {
      console.error(error);
    }
  }

  async function fetchSubscriptionListSU() {
    try {
      const response3 = await client.graphql({
        query: queries.listUserDetails,
        authMode: "API_KEY",
        variables: {
          limit: 100000,
          filter: {
            id: {
              eq: LoginUserDetails.assetOwnerId,
            },
          },
        },
      });
      const assetowner = response3.data.listUserDetails.items;
      console.log(assetowner);
      if (assetowner.length > 0) {
        const response = await client.graphql({
          query: queries.listSubscriptionLimits,
          authMode: "API_KEY",
          variables: {
            limit: 10000,
          },
        });
        if (response.data.listSubscriptionLimits.items.length > 0) {
          let plansData = [];
          let setdata = response.data.listSubscriptionLimits.items;
          console.log("sub setdata", setdata);
          let assetLimitdata = setdata.filter(
            (item) => item.subscriptionId === assetowner[0].paymentId
          );
          setOtherSubscriptions(
            setdata.filter(
              (item) => item.subscriptionId !== assetowner[0].paymentId
            )
          );
          setUserAddOn(
            setdata.filter(
              (item) => item.Type === "addOn" && item.subscriptionId === "7"
            )
          );
          setAssetAddOn(
            setdata.filter(
              (item) => item.Type === "addOn" && item.subscriptionId === "6"
            )
          );
          setSubscriptionlist(setdata);
          setassetLimit(assetLimitdata[0].AssetLimit);
          setsiteLimit(assetLimitdata[0].SiteLimit);
          setsiteuserLimit(assetLimitdata[0].SiteUserLimit);
          setspuserLimit(assetLimitdata[0].SPUserLimit);
          setsubscriptionName(assetLimitdata[0].SubscriptionName);
          // setsubscriptiondata(response.data.listSubscriptionLimits.items);
          // "If your company or organisation has a number of assets well in excess of our current packages, contact us at team@knowyourasset.com.au to discuss a tailored package to suit"

          for (let item of setdata) {
            if (item.Type === "plan") {
              let storage1Text =
                item.subscriptionId === "5"
                  ? "If your company or organisation has a number of assets well in excess of our current packages, click below to discuss a tailored package to suit."
                  : (item.MinAsset === null ? 0 : item.MinAsset) +
                  "-" +
                  item.AssetLimit;
              let props1Text = item.subscriptionId === "5" ? "" : "Assets";
              plansData.push({
                id: item.subscriptionId,
                prize: "$" + item.Price,
                title: item.SubscriptionName,
                month: "/month",
                storage1: storage1Text,
                props1: props1Text,
                // storage2: item.SiteLimit,
                storage2: "Unlimited",
                props2: "Sites",
                storage3: item.SiteUserLimit,
                // props3: 'SiteUserLimit',
                props3: "Users",
                // storage4: item.SPUserLimit,
                // props4: 'SP User Limit'
                // storage5: '15',
                // props5: 'Domains',
              });
            }
          }
          setSubscriptionsplansData(plansData);
          console.log("data of the subscription list--");
          console.log(setdata);
        }
      }
    }
    catch (error) {
      console.error(error);
    }
  }

  const topUpUser = async () => {
    try {
      setSubscriptionType("User Addon");
      setPrice({
        price: "price_1PoiQLIeIZdDV3cd70fxpeab",
        subId: "33811844-6e12-42bf-8785-bf494ae61ac5",
        id: "7",
      });
      setViewPayment(!viewPayment);
      // window.location.href = USERADDON + LoginUserDetails.id;
      // var myDate = new Date();
      // myDate.setMonth(myDate.getMonth() + 1);
    } catch (error) {
      console.error(error);
    }
  };

  const topUpAsset = async () => {
    try {
      setSubscriptionType("Asset Addon");
      setPrice({
        price: "price_1PoiQ1IeIZdDV3cdKOlprcSo",
        subId: "209f5125-6a1a-4b6b-a1da-33002119b153",
        id: "6",
      });
      setViewPayment(!viewPayment);
      // window.location.href = ASSETADDON + LoginUserDetails.id;
      // var myDate = new Date();
      // myDate.setMonth(myDate.getMonth() + 1);
    } catch (error) {
      console.error(error);
    }
  };

  const payment = async (formData) => {
    try {
      const currentDate = moment();
      const currentDate1 = moment();
      const nextMonthDate = currentDate.add(1, "month");

      const formattedStartDate = currentDate1.format("YYYY-MM-DD");
      const formattedDueDate = nextMonthDate.format("YYYY-MM-DD");
      const record = await client.graphql({
        query: mutations.createSubscriptionAdd,
        variables: {
          input: {
            userdetailsID: LoginUserDetails.id,
            subscriptionlimitID: price.subId,
            subscriptionId: price.id,
            paymentObjectBeforePayment: [],
            invoiceId: " ",
            status: "Inactive",
            startDate: formattedStartDate,
            dueDate: formattedDueDate,
            amount: 0,
            currentCount: siteUserCountOftheAO,
            quantity: formData.quantity,
            paymentObject: [],
          },
        },
        authMode: "API_KEY",
      });
      console.log(record);
      const session = await stripe.checkout.sessions.create({
        line_items: [
          {
            price: price.price,
            quantity: formData.quantity,
          },
        ],
        client_reference_id: LoginUserDetails.id,
        metadata: {
          addOn: subscriptionType,
          quantity: formData.quantity,
          subAddId: record.data.createSubscriptionAdd.id, // id from sub Add
        },
        mode: "subscription",
        success_url: `http://localhost:3000/subscriptions?success=true`,
        cancel_url: `http://localhost:3000/subscriptions?canceled=true`,
      });
      console.log("session", JSON.stringify(session));
      const recordupdate = await client.graphql({
        query: mutations.updateSubscriptionAdd,
        variables: {
          input: {
            id: record.data.createSubscriptionAdd.id,
            paymentObjectBeforePayment: JSON.stringify(session),
          },
        },
        authMode: "API_KEY",
      });
      console.log(recordupdate);
      window.location = session.url;
      formData.quantity = 0;
    } catch (err) {
      console.error("err", JSON.stringify(err));
    }
  };
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col md="12" className="project-list">
            <Card className="custom-card">
              {/* <CardHeader> */}
              <Row>
                <Col md="6">
                  {/* <H5>{"Current Subscription Plan : Plan- "+LoginUserDetails.paymentId}</H5>     */}
                  <H2>{"Current Subscription Plan : " + subscriptionName}</H2>
                </Col>
                {/* <Col md="6" className="text-end"></Col> */}
                <Col md="6" className="text-end">
                  <Btn
                    attrBtn={{ color: "primary", onClick: () => topUpUser() }}
                  >
                    Top-Up a user for $15 per month
                  </Btn>
                  {LoginUserDetails.paymentId === "3" && (
                    <Btn
                      attrBtn={{
                        color: "primary",
                        onClick: () => topUpAsset(),
                      }}
                    >
                      Top-Up 5000 Assets for $30 per month
                    </Btn>
                  )}
                </Col>
              </Row>
              {/* </CardHeader> */}
              <CardBody>
                <div className="card-block row">
                  <Col sm="12" lg="12" xl="12">
                    <div className="table-responsive">
                      <Table className="table-striped  table-de">
                        <thead className="custom-table-head">
                          <tr>
                            <th>Categories</th>
                            <th>SUbscriptions Limit</th>
                            <th>Used</th>
                            <th>Pending</th>
                          </tr>
                        </thead>
                        <tbody className="trhover">
                          <tr>
                            <td>Assets</td>
                            <td>{assetLimit}</td>
                            <td>{assetsCountOftheAO}</td>
                            <td>{assetLimit - assetsCountOftheAO}</td>
                          </tr>
                          <tr>
                            <td>Sites</td>
                            <td>{siteLimit}</td>
                            <td>{siteCountOftheAO}</td>
                            <td>{siteLimit - siteCountOftheAO}</td>
                          </tr>
                          <tr>
                            <td>Users</td>
                            <td>{siteuserLimit}</td>
                            <td>{siteUserCountOftheAO}</td>
                            <td>{siteuserLimit - siteUserCountOftheAO}</td>
                          </tr>
                          <tr>
                            <td>SP Users</td>
                            <td>{spuserLimit}</td>
                            <td>{spUserCountOftheAO}</td>
                            <td>{spuserLimit - spUserCountOftheAO}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </div>
              </CardBody>
            </Card>
          </Col>
          <H5 attrH5={{ className: "headingOfPlan" }}>
            Other Subscription Plans
          </H5>
          {subscriptionsplansData.map((item) => {
            return (
              <Col sm="6" xl="3" className="xl-50 box-col-6" key={item.id}>
                <Card className="pricing-block text-center ">
                  <div className="pricing-header">
                    <H2>{item.title}</H2>
                    <div className="price-box">
                      <div>
                        <H3>{item.prize}</H3>
                        <P>{item.month}</P>
                      </div>
                    </div>
                  </div>
                  <div className="pricing-list">
                    <UL attrUL={{ className: "simple-list pricing-inner" }}>
                      <LI value="Plan1" name="plan1">
                        <H6>
                          {item.props1}
                          <span>{item.storage1}</span>
                        </H6>
                      </LI>
                      {item.id !== "5" ? (
                        <>
                          <LI value="Plan2" name="plan2">
                            <H6>
                              {item.props2}
                              <span>{item.storage2}</span>
                            </H6>
                          </LI>
                          <LI value="Plan3" name="plan3">
                            <H6>
                              {item.props3}
                              <span>{item.storage3}</span>
                            </H6>
                          </LI>
                        </>
                      ) : (
                        ""
                      )}
                    </UL>
                    {
                      // assetsCountOftheAO >= parseInt(item.storage1) ||
                      //   siteCountOftheAO >= parseInt(item.storage2) ||
                      //   siteUserCountOftheAO >= parseInt(item.storage3) ||
                      //   spUserCountOftheAO >= parseInt(item.storage4) 
                      (!(Number(LoginUserDetails.paymentId) + 1 === Number(item.id)))
                        ?
                        (
                          <Button
                            attrBtn={{
                              color: "primary",
                              size: "lg",
                              onClick: () => onSubmit(item),
                            }}
                            disabled
                          >
                            {item.id !== "5" ? Subscribe : "Click Here"}
                          </Button>
                        ) : (
                          <Btn
                            attrBtn={{
                              color: "primary",
                              size: "lg",
                              onClick: () => onSubmit(item),
                            }}
                          >
                            {item.id !== "5" ? Subscribe : "Click Here"}
                          </Btn>
                        )}

                    <br></br>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
        <Modal
          isOpen={viewPayment}
          toggle={viewPaymentModaltoggle}
          size="lg"
          centered
        >
          <ModalHeader toggle={viewPaymentModaltoggle}>
            Top Up {subscriptionType}
          </ModalHeader>
          <ModalBody className="modal-body">
            <Container fluid={true}>
              <br></br>
              <Row>
                <form
                  className="needs-validation"
                  noValidate=""
                  onSubmit={handleSubmit(payment)}
                >
                  <Row>
                    <Col sm="4">
                      <Label>Quantity</Label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Quantity"
                        name="quantity"
                        min={1}
                        max={10}
                        {...register("quantity", { required: false })}
                      />
                      <span>
                        {errors.quantity &&
                          `Please Enter Number Of ${subscriptionType} you want to add.`}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-end">
                      <Btn attrBtn={{ color: "primary" }}>Pay</Btn>
                    </Col>
                  </Row>
                </form>
              </Row>
            </Container>
          </ModalBody>
        </Modal>
      </Container>
    </Fragment>
  );
};
export default SubscriptionsDetails;
